'use strict';

var baseClientSideValidation = require('base/components/clientSideValidation');
const $macInput = $('#mac-input');
const cache = {
    $phoneInputField: $('#phone, #mobileNumber'),
    $firstAndLastName: $('#fname, #lname'),
    $emailField: $('#email'),
    $neverbounceEmailField: $('.js-email-neverbounce'),
    tokenVal: $('#csrf-token').val()
};

// Allows names like: Dr. D'angelo/ Anna-Maria/ Anna Maria
const namesRegex = /^[A-Za-z\'\s\.\,\-\d]+$/;    // eslint-disable-line

/**
 * Mask mac addresses
 */
function maskMacInputField() {
    $macInput.mask('AA:AA:AA:AA:AA:AA', {
        onKeyPress: function (str, _, obj) {
            $(obj).val(str.toUpperCase());
        }
    });
}

/**
 * Mask phone inputs to match the US phone number format
 * Mask first and last names inputs to disallow numbers and symbols via namesRegex constant
 */
function maskInputFieldsRules() {
    cache.$phoneInputField.mask('(000) 000-0000');
    cache.$firstAndLastName.mask('Z', {
        translation: {
            Z: {
                pattern: namesRegex,
                recursive: true
            }
        }
    });
    cache.$phoneInputField.on('focus', () => {
        cache.$phoneInputField.attr('autocomplete', 'no');
    });
}

/**
 * Decorates an input field with async email validation
 * The input field is required to have a data-email attribute with validation endpoint e.g Appointment-VerifyExistingAccount
 * @param {element} inputField - email input field
 * @param {element} errorMessageEl - error message element
 */
const asyncEmailValidationDecorator = (inputField, errorMessageEl) => {
    // Setup
    const $el = $(inputField);

    if (!$el.length) {
        return;
    }

    const $errorMsgEl = $(errorMessageEl);
    const url = $el.data('email');
    let lastQuery;

    $el.data({
        isEmailCorrect: false,
        isUserExist: false
    });

    // Verify
    if (!url) {
        console.warn(`Email Validation requires an "email" data attribute in ${$el.get(0)}`);  // eslint-disable-line
        return;
    }

    // Rendering
    const renderValid = () => {
        $el.removeClass('nb__is-invalid');
        $errorMsgEl.hide();
        $el.trigger('input:valid');
    };

    const renderInvalid = () => {
        $el.addClass('nb__is-invalid');
        $errorMsgEl.show();
        $el.trigger('input:invalid');
    };

    const renderPending = () => {
        $el.addClass('pending');
        $el.prop('disabled', true);
        $el.trigger('input:validationPending');
    };

    const renderComplete = () => {
        $el.removeClass('pending');
        $el.prop('disabled', false);
        $el.trigger('input:validationComplete');
    };

    // Email Validate XHR
    let getData = async ({ email }) => {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: url,
                method: 'POST',
                data: {
                    email: email,
                    legalAge: true,
                    csrf_token: cache.tokenVal
                },
                success: (data) => {
                    resolve(data);
                },
                error: (err) => {
                    reject(err);
                }
            });
        });
    };

    // Events
    $el.on('focusout', async () => {
        // Is email valid format?
        if (!$el.get(0).checkValidity()) {
            $el.data({
                isEmailCorrect: false,
                isUserExist: false
            });
            return;
        }

        let email = $el.val();
        // If same as previous query, return valid
        if (email === lastQuery) {
            renderValid();
            return;
        }

        // Make request
        try {
            renderPending();
            var result = await getData({ email });
            renderComplete();

            lastQuery = email;
            const { isEmailCorrect, isUserExist } = result;

            $el.data({
                isEmailCorrect,
                isUserExist
            });

            if (isEmailCorrect) {
                renderValid();
            } else {
                renderInvalid();
            }
        } catch (e) {
            console.warn(e); // eslint-disable-line
            renderComplete();
            renderInvalid();
        }
    });
};

// Using $.extend we can pass in the original clientSideValidation file and then anything provided will either be overwritten or added.
module.exports = $.extend({}, baseClientSideValidation, {
    initEvents: function () {
        maskInputFieldsRules();
        maskMacInputField();
        asyncEmailValidationDecorator(cache.$emailField, cache.$neverbounceEmailField);
    },
    invalid: function () {
        $('form input, form select').on('invalid focusout', function (e) {
            e.preventDefault();
            this.setCustomValidity('');
            if (!this.validity.valid) {
                var validationMessage = this.validationMessage;
                $(this).addClass('is-invalid');
                if (this.validity.patternMismatch && $(this).data('pattern-mismatch')) {
                    validationMessage = $(this).data('pattern-mismatch');
                }
                if ((this.validity.rangeOverflow || this.validity.rangeUnderflow)
                    && $(this).data('range-error')) {
                    validationMessage = $(this).data('range-error');
                }
                if ((this.validity.tooLong || this.validity.tooShort)
                    && $(this).data('range-error')) {
                    validationMessage = $(this).data('range-error');
                }
                if (this.validity.valueMissing && $(this).data('missing-error')) {
                    validationMessage = $(this).data('missing-error');
                }
                $(this).parents('.form-group').find('.invalid-feedback')
                    .text(validationMessage);
            } else {
                $(this).removeClass('is-invalid');
            }
        });
    }
});
