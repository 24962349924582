'use strict';

const EVENT_ERROR = 'addressChecker:error';
const EVENT_EMPTY_SUBMIT = 'addressChecker:emptySubmit';
const { createModal } = require('../helpers/modalHelper');

/**
 * Populates the hidden form inputs with the addressObj properties
 * @param {Object} addressObj containing address properties
 * @param {*} form element to populate
 */
const mapAddressToFormValues = (addressObj, form) => {
    const $form = $(form);
    const inputToObjectMap = {
        long: '_addressChecker_long',
        lat: '_addressChecker_lat',
        street_number: '_addressChecker_streetnumber',
        route: '_addressChecker_street',
        locality: '_addressChecker_city',
        administrative_area_level_1: '_addressChecker_states_stateCode',
        state_name_long: '_addressChecker_stateCodeLong',
        postal_code: '_addressChecker_zipcode',
        country: '_addressChecker_countrycode',
        isRooftop: '_addressChecker_isRooftop',
        addressGoogleFormatted: '_addressChecker_addressGoogleFormatted'
    };
    Object.keys(inputToObjectMap).forEach((item) => {
        const inputName = inputToObjectMap[item];
        const inputVal = addressObj[item];
        $(`[name=dwfrm${inputName}]`, $form).val(inputVal);
    });
};

/**
 * Async request to the getGeoCodeData API
 * @param {string} address to geocode
 * @returns {Object} an address object
 */
const fetchAddressSuggestionObj = async (address) => {
    const googleHelper = require('telco/helpers/googleHelper');
    return new Promise((resolve) => {
        googleHelper.getGeocodeData(address).then(geocodeResult => {
            resolve(googleHelper.getAddressObj(geocodeResult));
        });
    });
};

/**
 * Prepurchase response handler
 * @param {Object} params - an object
 * @param {string} params.redirectUrl the url to redirect the browser
 * @param {string} params.modalContent the modal html
 * @param {string} params.polygonDate the date for dynamic replacement
 */
const handlePrePurhcaseResponse = ({ redirectUrl, modalContent, polygonDate }) => {
    const modal = createModal({ html: modalContent });
    $('#js-purchase', modal).text(polygonDate);
    $('.js-btn-purchase').one('click', function () {
        location.href = redirectUrl;
    });
};

/**
 * "Let me Know" response handler
 * @param {Object} params - an object
 * @param {string} params.modalContent the modal html
 * @param {string} params.polygonDate the date for dynamic replacement
 */
const handleLetMeKnowResponse = ({ modalContent, polygonDate }) => {
    const modal = createModal({ html: modalContent });
    $('#js-know', modal).text(polygonDate);
};

/**
 * "Customet Attention" response handler
 * @param {Object} params - an object
 * @param {string} params.modalContent the modal html
 */
const handleCustomerAttentionResponse = ({ modalContent, polygonDate }) => {
    const modal = createModal({ html: modalContent });
    $('#js-know', modal).text(polygonDate);
};

/**
 * Default ajax respons handler
 * @param {Object} params - params object
 * @param {string} params.redirectUrl the url to redirect the browser
 */
const handleDefaultResponse = ({ redirectUrl }) => {
    location.href = redirectUrl;
};

/**
 * Handles submission and handling of address checker form
 * @param {Event} e form submit event
 */
const handleFormSubmit = async function (e) {
    const $form = $(this);
    const url = $form.attr('action');
    const $addressInputEl = $('input[name*=_addressChecker_serviceAddress]', $form);

    e.preventDefault();

    // button tracking
    let currentCtlCustomer = $('#centurylink-existing-yes').prop('checked') ? 'yes' : 'no';
    $(this).trigger('addresscheck:action', { btn: 'check availability', currentCtlCustomer });

    // Redirect to address checker if empty submit
    if ($addressInputEl.val().trim() === '') {
        e.stopPropagation();
        $form.trigger(EVENT_EMPTY_SUBMIT);
        return;
    }


    try {
        $.spinner().start();
        const addressObj = await fetchAddressSuggestionObj($addressInputEl.val());
        mapAddressToFormValues(addressObj, $form);

        $.ajax({
            url: url,
            data: $form.serialize(),
            method: 'POST'
        })
            .done(function (data) {
                if (!data.success) {
                    $form.trigger(EVENT_ERROR);
                    return;
                }

                const redirectUrl = data.redirectUrl;
                const modalContent = data.modalContent;

                const { createPolygonDate } = require('telco/helpers/polygonDateHelper');
                const polygonDate = createPolygonDate(data.polygonEcd);

                switch (data.modal) {
                    case 'prePurchase':
                        handlePrePurhcaseResponse({ redirectUrl, modalContent, polygonDate });
                        break;
                    case 'letMeKnow':
                        handleLetMeKnowResponse({ modalContent, polygonDate });
                        break;
                    case 'customerAttention':
                        handleCustomerAttentionResponse({ modalContent, polygonDate });
                        break;
                    default:
                        handleDefaultResponse({ redirectUrl });
                        break;
                }
            })
            .fail(() => {
                $form.trigger(EVENT_ERROR);
            })
            .always(function () {
                $.spinner().stop();
            });
    } catch (err) {
        $form.trigger(EVENT_ERROR);
    }
};

/**
 * Initializes the Google Address Suggestions component
 * @param {HTMLInputElement} addressInputEl the address input html element
 */
const initAddressAutoComplete = (addressInputEl) => {
    const options = {
        componentRestrictions: { country: 'us' },
        fields: ['formatted_address'],
        types: ['address']
    };

    const initAutocomplete = () => {
        return new window.google.maps.places.Autocomplete(addressInputEl, options);
    };

    if ('google' in window && window.google.maps) {
        initAutocomplete();
    } else {
        $(document).on('googleLoaded', initAutocomplete);
    }
};

/**
 * Initializes the Address Checker Component
 * @param {*} containerEl the wrapping container
 */
const initAddressCheckerComponent = (containerEl) => {
    const $el = $(containerEl);
    const $form = $('.js-address-checker-form', $el);
    const $addressInputEl = $('input[name*=_addressChecker_serviceAddress]', $el);
    const errorModalURL = $el.data('modal-error-url');
    const emptySubmitURL = $el.data('empty-submit-url');

    // Setup Address Autocomplete
    initAddressAutoComplete($addressInputEl.get(0));

    $addressInputEl.removeAttr('required');

    // Handle error events triggered from form
    $el.on(EVENT_ERROR, function () {
        const { loadModal } = require('../helpers/modalHelper');
        loadModal({ url: errorModalURL });
    });

    // Redirect to Address Checker on empty submit
    $el.on(EVENT_EMPTY_SUBMIT, function () {
        window.location.href = emptySubmitURL;
    });

    // Handle form submits
    $form.on('submit', handleFormSubmit);
};

module.exports.init = () => {
    const $addressCheckerWidget = $('.js-address-checker-widget');
    $addressCheckerWidget.each(function () {
        initAddressCheckerComponent(this);
    });
};
