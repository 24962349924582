'use strict';
var CryptoJS = require("crypto-js");

window.dataLayer = window.dataLayer || [];
const dataLayer = window.dataLayer;

const GTM_EVENT_TIMEOUT = 2000;
const PRODUCT_FIBER_INTERNET = 'Fiber Internet';
const PRODUCT_360_WIFI = '360 WiFi';
const PRODUCT_CONNECTED_VOICE = 'Connected Voice';
const CODE_FIBER_INTERNET =  'fbr';
const CODE_360_WIFI = 'mw';
const CODE_CONNECTED_VOICE = 'cv';

let pageData = $('#pageInfo').data('page-info');
let pageTitle = $('#pageInfo').data('page-title') || document.title;
let category = pageData ? pageData.category : '';
let plpStepName = 'select ' + category;
let flow_name = window.localStorage.getItem('flow');

function initDatalayer() {
    if (!pageTitle) return;

    let userEmail = JSON.stringify($('#userEmail').val());
    let useraccountId = JSON.stringify($('#useraccountId').val());
    let userEmailEncrypted = CryptoJS.AES.encrypt(userEmail, 'Part Window Trick Discussion Wilderness Creature').toString();
    let useraccountIdEncrypted = CryptoJS.AES.encrypt(useraccountId, 'Part Window Trick Discussion Wilderness Creature').toString();
    let offerName = $('.bf__main').data('contracted-speed');
    if (offerName) {
        offerName = offerName.toLowerCase() === 'non-bulk' ? 'non-bulk': 'bulk';
    }

    let eventObj = {
        page: {
            pageType: 'quantum',
            pageName: pageTitle
        },
        flow: {
            name: flow_name
        },
        accountStatus: $('#accountStatus').val(),
        customer:{
            accountId: useraccountIdEncrypted
        },
        customerEmail: userEmailEncrypted,
        offerName: offerName ? offerName : ''
    };

    dataLayer.push(eventObj);
    sendChatbotData();
}

function createEventObj(options) {
    return {
        event: options.event,
        flow: {
            name: options.flow_name ? options.flow_name : window.localStorage.getItem('flow'),
            step: options.step
        },
    };
}

/**
 * getProductNameByCode
 * @param {string} code - product code
 * @returns {string} product name
 */
function getProductNameByCode(code) {
    if (code == CODE_FIBER_INTERNET) {
        return PRODUCT_FIBER_INTERNET;
    } else if (code == CODE_360_WIFI) {
        return PRODUCT_360_WIFI;
    } else if (code == CODE_CONNECTED_VOICE) {
        return PRODUCT_CONNECTED_VOICE;
    }

    return '';
}

/**
 *  getDomain(urlInput, subdomainInput)
 * @param {string} urlInput - domain from href in link
 * @param {boolean|undefined} subdomainInput - subdomain will be included or not
 * @returns {string} - domain
 */
function getDomain(urlInput, subdomainInput) {
    let subdomain = subdomainInput || false;
    let url = urlInput.replace(/(https?:\/\/)?(www.)?/i, '');
    if (!subdomain) {
        url = url.split('.');
        url = url.slice(url.length - 2).join('.');
    }

    if (url.indexOf('/') !== -1) {
        return url.split('/')[0];
    }
    return url;
}

/* ============================== Send Chatbot Data ============================== */
function sendChatbotData() {
    let eventObj = createEventObj({
        event: 'chatbotAuthEvent',
        step: 'send chatbot data'
    });
    eventObj.chatbotAccountId = encrypted_account_id;

    dataLayer.push(eventObj);
}

/* ============================== AddressChecker ============================== */
function addressCheckAction(ev, data) {

    let eventObj = createEventObj({
        event: 'check_availability',
        step: 'check availability'
    });
    eventObj.availabilityButton = data.btn;
    eventObj.currentCtlCustomer = data.currentCtlCustomer;

    dataLayer.push(eventObj);

    if (data.btn == 'check availability' && $('#unitNumber').val()) {
        let eventObj2 = createEventObj({
            event: 'qf_mdu_address',
            step: 'check availability'
        });
        dataLayer.push(eventObj2);
    }
}

/* ============================== Pay Outstanding Balance Result============================== */
function outstandingBalancePushResult (ev, data) {
    let eventObj = createEventObj({
        event: 'qf_pay_outstanding_balance',
        flow_name:'quantum - ec',
        step: 'pay outstanding balance'
    });
    eventObj.payBalance = data.status;
    dataLayer.push(eventObj);
};
/* ============================== view Invoice ============================== */
function viewInvoicePush () {
    let eventObj = createEventObj({
        event: 'qf_view_invoice',
        flow_name: 'quantum - ec',
        step: 'my payments'
    });
    dataLayer.push(eventObj);

}
/* ============================== Sign In Header ============================== */
function hSignInPush () {
    let eventObj = createEventObj({
        event: 'qf_signin_click',
        flow_name: 'quantum',
        step: 'sign in'
    });
    dataLayer.push(eventObj);

}
/* ============================== Edit account Info ============================== */
function accountinfoPush () {
    let eventObj = createEventObj({
        event: 'qf_ec_edit_button',
        flow_name: 'quantum - ec',
        step: 'edit account Info'
    });
    dataLayer.push(eventObj);

}

function accountEditInfoPush () {
    let buttonName = $(this).data('edit-attr');
    let eventObj = createEventObj({
        event: 'qf_ec_edit_button',
        flow_name: 'quantum - ec',
    });
    eventObj.buttonName = buttonName;
    dataLayer.push(eventObj);

}
/* ============================== Update Payment Method ============================== */
function updatePaymentMethodPush () {
    let eventObj = createEventObj({
        event: 'qf_ec_update_payment_link',
        flow_name: 'quantum - ec',
        step: 'update payment'
    });
    dataLayer.push(eventObj);

}
/* ============================== Resume Service ============================== */
function resumeServicePush () {
    let eventObj = createEventObj({
        event: 'qf_resume_service',
        flow_name: 'quantum - ec',
        step: 'resume service'
    });
    let product_list = '';
    let product_elements = document.querySelectorAll('.qf-modal__subscription-value');
    product_elements.forEach(function(product) {
        product_list = product_list + product.innerHTML.trim() + ',';
    });
    product_list.slice(0, -1);
    eventObj.resumeProduct = product_list;
    dataLayer.push(eventObj);
}

/* ============================== Cancel Pending Order ============================== */
function cancelPendingOrderPush () {
    let eventObj = createEventObj({
        event: 'qf_start_cancel_order',
        flow_name: 'quantum - ec',
        step: 'start cancel order'
    });
    eventObj.orderProduct = $('.mp-table__row-item').eq(2).text().trim().replace(/\s\s/g, '').replace('Order product ', '');
    dataLayer.push(eventObj);

}
/* ============================== Confirm Cancel Pending Order ============================== */
function confirmCancelPush () {
    let eventObj = createEventObj({
        event: 'qf_confirm_cancel_order',
        flow_name: 'quantum - ec',
        step: 'confirm cancel order'
    });
    eventObj.orderProduct = $('.mp-table__row-item').eq(2).text().trim().replace(/\s\s/g, '').replace('Order product ', '');
    dataLayer.push(eventObj);
}

/* ============================== Password Change Save ============================== */
function passwordChangePush () {
    let eventObj = createEventObj({
        event: 'qf_change_password',
        flow_name: 'quantum - ec',
        step: 'change password',
    });
    dataLayer.push(eventObj);
}
/* ============================== Communication Preferences ============================== */
function comPrefPush () {
    let eventObj = createEventObj({
        event: 'qf_ec_confirm_changes',
        flow_name: 'quantum - ec',
        step: 'communication preferences',
    });
    dataLayer.push(eventObj);

}
/* ============================== Manage Products Push ============================== */
function manageProductPush () {
    let eventObj = createEventObj({
        event: 'qf_ec_manage_products',
        flow_name: 'quantum - ec',
        step: 'manage products',
    });
    dataLayer.push(eventObj);

}
/* ============================== Cancel Sub Push ============================== */
function cancelSubPush () {
    let eventObj = createEventObj({
        event: 'qf_ec_cancel_subscription',
        flow_name: 'quantum - ec',
        step: 'cancel subscription',
    });
    dataLayer.push(eventObj);

}
/* ============================== Resume Sub Push ============================== */
function resumeSubPush () {
    let eventObj = createEventObj({
        event: 'qf_ec_resume_subscription',
        flow_name: 'quantum - ec',
        step: 'resume subscription',
    });
    dataLayer.push(eventObj);

}
/* ============================== Remove Product Push1 ============================== */
/**
 * removeProductOnFormSubmit
 * @param {Object} event jQuery.Event object.
 * @param {Object|Array} args Additional parameters to pass along to the event handler.
 */
function removeProductOnFormSubmit(event, args) {
    let $form = args.form;
    let productAbbr = args.productAbbr;
    let eventObj = createEventObj({
        event: 'qf_remove_product',
        flow_name: 'quantum - ec',
        step: 'remove product'
    });
    if (typeof productAbbr !== 'undefined') {
        eventObj.removedProduct = productAbbr;
    } else {
        let code = $form.find('input[name=dwfrm_cancelSubscription_selectedProduct]').val();
        eventObj.removedProduct = getProductNameByCode(code);
    }
    dataLayer.push(eventObj);
}

/* ============================== BBU appointment button  ============================== */
function batteryButtonPush () {
    let eventObj = createEventObj({
        event: 'qf_purchase_bbu',
        flow_name: 'quantum - ec',
        step: 'shop',

    });
    dataLayer.push(eventObj);

}
/* ============================== Purchase OTT  ============================== */
function tvServiceClickPush () {
    let eventObj = createEventObj({
        event: 'qf_add_ec_product',
        flow_name: 'quantum - ec',
        step: 'add product',
    });
    eventObj.addedProduct = getDomain($(this).attr('href'));
    dataLayer.push(eventObj);
}
/* ============================== Add network device  ============================== */
function addDevicePush () {
    let eventObj = createEventObj({
        event: 'qf_add_network_device',
        flow_name: 'quantum - ec',
        step: 'add network device',
    });
    dataLayer.push(eventObj);

}
/* ============================== remove network device  ============================== */
function removeDeviceNetwork () {
    let eventObj = createEventObj({
        event: 'qf_remove_network_device',
        flow_name: 'quantum - ec',
        step: 'remove network device',
    });
    dataLayer.push(eventObj);

}
/* ============================== remove network device  ============================== */
function removeDevicePush () {
    let eventObj = createEventObj({
        event: 'qf_remove-network-device',
        flow_name: 'quantum - ec',
        step: 'remove network device',
    });
    dataLayer.push(eventObj);

}
/* ============================== Change Wifi Password  ============================== */
function ChangeWifiPush () {
    let eventObj = createEventObj({
        event: 'qf_change_wifi_password',
        flow_name: 'quantum - ec',
        step: 'change wifi password',
    });
    dataLayer.push(eventObj);

}
/* ============================== Change Appointment  ============================== */
function rescheduleAppPush () {
    let eventObj = createEventObj({
        event: 'qf_start_change_appointment',
        flow_name: 'quantum - ec',
        step: 'start change appointment'
    });
    eventObj.appointmentNumber = $('.appt-line-item').eq(1).text();
    dataLayer.push(eventObj);
}
/* ============================== Change Appointment confirm  ============================== */
function rescheduleAppPushConfirm () {
    let eventObj = createEventObj({
        event: 'qf_confirm_change_appointment',
        flow_name: 'quantum - ec',
        step: 'confirm change appointment',
    });
    eventObj.appointmentNumber = $('.appt-line-item').eq(1).text();
    eventObj.cancelReason = $('#select2-appt-cancel-reason-container').text();
    dataLayer.push(eventObj);

}
/* ============================== contact us ============================== */
function contactUsPush () {
    let eventObj = createEventObj({
        event: 'qf_contact_us',
        flow_name: 'quantum - ec',
        step: 'service and support'
    });
    let contact_type = '';
    if ($(this).text().trim().includes("support")) {
        contact_type = 'support';
    } else if ($(this).text().trim().includes(".com")) {
        contact_type = 'email';
    } else if ($(this).text().trim().match(/.*\d{3}-.*/)) {
        contact_type = 'telephone';
    } else if ($(this).text().trim().includes("chat")) {
        contact_type = 'chat';
    }
    eventObj.contactType = contact_type;
    dataLayer.push(eventObj);

}
/* ============================== Pause Service Proceed  ============================== */
function pauseServicePush () {
    let eventObj = createEventObj({
        event: 'qf_pause_service',
        flow_name: 'quantum - ec',
        step: 'pause service',
    });
    eventObj.pausedProduct = 'All products';
    dataLayer.push(eventObj);

}
/* ============================== ConnectedVoice  ============================== */
function connectedVoicePush () {
    let eventObj = createEventObj({
        event: 'qf_purchase_voice',
        flow_name: 'quantum - ec',
        step: 'shop'
    });
    dataLayer.push(eventObj);

}

function cvPhoneOption () {
    let eventObj = ({
        flow:{
            name: 'qf_purchase_voice',
            step: 'with phone option'
        }
    });
    dataLayer.push(eventObj);

}

function cvContinueWithoutphone () {
    let eventObj = createEventObj({
        event: 'qf_migration_continue_no_phone',
        flow_name: 'qf – migration',
        step: 'with phone option'
    });
    dataLayer.push(eventObj);

}

function cvAllProductDetails (event, productDetails) {
    let eventObj = createEventObj({
        event: 'product_impression',
        flow_name: 'qf – migration',
        step: 'with phone option',
    });
    eventObj.ecommerce = {impressions: productDetails},
    dataLayer.push(eventObj);

}

/* ============================== Change Speed  ============================== */
function changeSpeedPush () {
    let eventObj = createEventObj({
        event: 'qf_start_change_product',
        flow_name: 'quantum - ec',
        step: 'start change product'
    });
    eventObj.customer = {};
    eventObj.customer.currentProduct = $('.mp-shop__fiber--current .product').data('pname');
    eventObj.customer.currentPrice = $('.mp-shop__fiber--current .product').data('price');

    dataLayer.push(eventObj);
}
/* ============================== Continue change Product  ============================== */
function continueChangePush () {
    let eventObj = createEventObj({
        event: 'qf_continue_change_product',
        flow_name: 'quantum - ec',
        step: 'continue change product'
    });
    eventObj.customer = {currentProduct: $(".mp-tile__confirm .mp-card__details:not(.mp-shop .mp-card__details)").eq(1).siblings(".mp-shop").children(".product").data('pname')};
    eventObj.customer.currentPrice = $(".mp-tile__confirm .mp-card__details:not(.mp-shop .mp-card__details)").eq(1).siblings(".mp-shop").children(".product").data('price');
    dataLayer.push(eventObj);

}
/* ============================== Confirm Change Product  ============================== */
function confirmChangePush () {
    let eventObj = createEventObj({
        event: 'qf_confirm_change_product',
        flow_name: 'quantum - ec',
        step: 'confirm change product'
    });
    function return_product_details(section_name, details_class_selector) {
        let product_details = "";
        $(".summary__header").filter(function() {
            return $(this).text().trim() == section_name;
        }).parents(".summary").siblings(".mp-card__list-item").find(details_class_selector).each(function() {
            product_details = product_details + $(this).text().trim().replace(/\s\s/g, "").replace(/- \(/, " - (") + ",";
        });
        return product_details.slice(0, -1);
    }
    eventObj.customer = {currentProduct: return_product_details('Existing products', '.summary__item-name')};
    eventObj.customer.currentPrice = return_product_details('Existing products', '.summary__item-price');
    eventObj.customer.newProduct = return_product_details('New products', '.summary__item-name');
    eventObj.customer.newPrice = return_product_details('New products', '.summary__item-price');
    dataLayer.push(eventObj);
}
/* ============================== Cancel product/ subscription  ============================== */
function cancelProductOrSubscriptionStart(event, args) {
    let $form = args.form;
    let selectedProductCode = $form.find('#selectedProduct').val();
    let removedProduct = getProductNameByCode(selectedProductCode);
    let isProduct = CODE_FIBER_INTERNET !== selectedProductCode;
    let eventObj = {};

    if (isProduct) {
        eventObj = createEventObj({
            event: 'qf_cancel_product',
            flow_name: 'quantum - ec',
            step: 'cancel product'
        });
    } else {
        eventObj = createEventObj({
            event: 'qf_confirm_cancel_subscription',
            flow_name: 'quantum - ec',
            step: 'confirm cancel subscription'
        });
    }

    let starRating = 0;
    let $starImputList = $form.find(".rating .star-input");
    if ($starImputList.is(":checked")) {
        starRating = $starImputList.filter(function() {
            return $(this).is(":checked");
        }).attr("id").replace(/^\D+/g, '');
    }

    eventObj.starRating = starRating;
    eventObj.cancelReason = $form.find('#select2-mp-cancel-reason-select-container').text();
    eventObj.removedSubscription = removedProduct;
    dataLayer.push(eventObj);
}

/* ============================== remove subscription  ============================== */
function removeSubPush () {
    let eventObj = createEventObj({
        event: 'qf_dc_start_remove_subscription',
        flow_name: 'quantum - ec',
        step: 'cancel subscription'
    });
    dataLayer.push(eventObj);

}

function pinDropAction(ev, data) {
    let eventObj = createEventObj({
        event: 'retry_address',
        step: 'retry address'
    });
    eventObj.availabilityButton = data.btn;

    dataLayer.push(eventObj);
}

function pinDropMapAction(ev, data) {
    let eventObj = createEventObj({
        event: 'update_map',
        step: 'retry address map'
    });
    eventObj.availabilityButton = data.btn;

    dataLayer.push(eventObj);
}

function notifySuccessAction() {
    let eventObj = createEventObj({
        event: 'find_your_plan',
        step: 'notify me'
    });

    dataLayer.push(eventObj);
}

function noServiceAction(ev, data) {
    let eventObj = createEventObj({
        event: 'service_not_available',
        step: 'bummer'
    });
    eventObj.notifyButton = data.btn,

        dataLayer.push(eventObj);
}

function noServiceCtl(ev, data) {
    let eventObj = createEventObj({
        event: 'visit_ctl',
        step: 'bummer'
    });

    dataLayer.push(eventObj);
}

function notifyMeAction(ev, data) {
    let eventObj = createEventObj({
        event: 'notify_me_click',
        step: 'notify me'
    });
    eventObj.notifyButton = data.btn,

        dataLayer.push(eventObj);
}
function getHighest ( arr , prop, n) {
    var clone = arr.slice(0);
// sort descending
    clone.sort(function(x, y) {
        if (x[prop] == y[prop]) return 0;
        else if (parseInt(x[prop]) < parseInt(y[prop])) return 1;
        return -1;});
    return clone.slice(0, n);
}

/* ============================== Search ============================== */
function addressVerification(ev, data) {
    let loopQualName;
    let loopQualSpeed;
    let loopQualTech;
    let loopQualPrice;
    let loopQualAddress;
    let loopQualZip;
    let jsond=data.impressions;
    let addresszip=JSON.stringify(data.fulladdress);
    loopQualAddress = CryptoJS.AES.encrypt(addresszip, 'Part Window Trick Discussion Wilderness Creature').toString();
    let zipcode=addresszip.substring(addresszip.length - 6);
    let zipnum =$('#bf-address-edit-mobile').html();
    loopQualZip = CryptoJS.AES.encrypt(zipnum, 'Part Window Trick Discussion Wilderness Creature').toString();
    let eventObj = createEventObj({
        event: 'address_verification',
        step: 'address verification'
    });
    var n = 1;
    var highestPlan = getHighest(jsond, 'price', n);
    highestPlan.forEach(function(item, index) {
        loopQualName=item.name;
        loopQualSpeed=item.variant ? parseInt(item.variant.replace(/[^\d.-]/g, '')) : 0;
        loopQualPrice=item.price;
        loopQualTech=item.category;
    });
    eventObj.loopQualStatus = data.loopQualStatus;
    eventObj.speedUpload = data.uploadspeed;
    eventObj.speedDownload = data.downloadspeed;
    eventObj.loopQualName=loopQualName;
    eventObj.loopQualSpeed=loopQualSpeed;
    eventObj.loopQualTech=loopQualTech;
    eventObj.loopQualPrice=loopQualPrice;
    eventObj.loopQualAddress=loopQualAddress;
    eventObj.loopQualZip=loopQualZip;
    dataLayer.push(eventObj);
}

function productImpressions(ev, data) {
    let eventObj = createEventObj({
        event: 'product_impression',
        step: plpStepName
    });

    eventObj.ecommerce = {
        impressions: data.impressions
    };

    data.impressions.forEach(function(element) {
        if (element.price == "$30.00" && element.variant == "Up to 200 Mbps") {
            eventObj.activeTest = "speeds|nfp|version_a(2022)";
        } else if (element.price == "$49.00" && element.variant == "Up to 200 Mbps") {
            eventObj.activeTest = "speeds|nfp|version_b(2022)";
        }
    });

    dataLayer.push(eventObj);
}

function productSelect(ev, data) {
    let eventObj = createEventObj({
        event: 'product_click',
        step: plpStepName
    });

    eventObj.ecommerce = {
        click: {
            actionField: {
                list: data.actionField
            },
            products: data.products
        }
    };
    dataLayer.push(eventObj);
}

function moreDetails(ev, data) {
    let eventObj = createEventObj({
        event: 'product_detail_view',
        step: plpStepName
    });

    eventObj.ecommerce = {
        detail: {
            actionField: {
                list: data.actionField
            },
            products: data.products
        }
    };
    dataLayer.push(eventObj);

}

function productAction(ev, data) {
    let eventObj = createEventObj({
        event: data.btn.toLowerCase(),
        step: plpStepName
    });

    dataLayer.push(eventObj);
}
function scheduleInstall(ev, data) {
    let earliestInstall = 'N/A';
    if (data.earliestServiceableDate) {
        let earliestServiceableDate = data.earliestServiceableDate;
        let today = new Date();
        let diff = earliestServiceableDate - today;
        let diffDays = Math.ceil(diff/1000/60/60/24);
        earliestInstall = diffDays; // This is the amount of dates remaining between today and earlist serviceable date
    }
    let eventObj = createEventObj({
        event: 'installation_impression',
        step: 'schedule installation'
    });
    eventObj.earliestInstall = earliestInstall;
    dataLayer.push(eventObj);
}

function scheduleActive(ev, data) { // activation impression
    let earliestInstall = 'N/A';
    if (data.earliestServiceableDate) {
        let earliestServiceableDate = data.earliestServiceableDate;
        let today = new Date();
        let diff = earliestServiceableDate - today;
        let diffDays = Math.ceil(diff/1000/60/60/24);
        earliestInstall = diffDays; // This is the amount of dates remaining between today and earlist serviceable date
    }
    let eventObj = createEventObj({
        event: 'activation_impression',
        step: 'schedule activation'
    });
    eventObj.earliestInstall = earliestInstall;
    dataLayer.push(eventObj);
}

function scheduleSubmit(ev, data) {
    let installDateInt = 'N/A';
    let today = new Date();
    let instDate= new Date(data.installDate);
    let diff = instDate - today;
    let diffDays = Math.ceil(diff/1000/60/60/24);
    installDateInt = diffDays; // This is the amount of dates remaining between today and install date

    let eventObj = createEventObj({
        event: 'schedule_install',
        step: 'schedule installation'
    });

    eventObj.installDate = installDateInt;
    dataLayer.push(eventObj);
}

function scheduleActiveSubmit(ev, data) { // activation submit
    let installDateInt = 'N/A';
    let today = new Date();
    let instDate= new Date(data.installDate);
    let diff = instDate - today;
    let diffDays = Math.ceil(diff/1000/60/60/24);
    installDateInt = diffDays; // This is the amount of dates remaining between today and install date

    let eventObj = createEventObj({
        event: 'schedule_activation',
        step: 'schedule activation'
    });

    eventObj.installDate = installDateInt;
    dataLayer.push(eventObj);
}

function orderReviewShow() {
    let eventObj = createEventObj({
        event: 'order_review',
        step: 'Product Selection'
    });
    eventObj.flow.stepType= 'product';
    dataLayer.push(eventObj);
}

function orderReviewAction(ev) {
    let eventObj = createEventObj({
        event: 'add_to_cart',
        step: 'continue to payment'
    });
    let productData = pageData ? pageData.productData : [];
    eventObj.ecommerce = {
        add: {
            products: productData
        }
    };

    dataLayer.push(eventObj);

}
function paymentSubmit(ev, data) {
    let eventObj = createEventObj({
        event: 'submit_order',
        step: 'submit order'
    });
    let productData = pageData ? pageData.productData : [];
    eventObj.ecommerce = {
        checkout: {
            actionField: { 'step': 1 },
            products: productData
        }
    };
    eventObj.customer = {
        company: data.company ==='yes'? data.company: 'no'
    };

    dataLayer.push(eventObj);
}

function confirmationNextSteps(ev, data) {
    let eventObj = createEventObj({
        event: 'next_step_click',
        step: 'next steps'
    });
    eventObj.nextStepButton = data.btn;

    dataLayer.push(eventObj);
}

function confirmationShow(ev, data) {
    let eventObj = createEventObj({
        event: 'purchase',
        step: 'purchase confirmation'
    });
    let bftstrans =data.orderid.replace(/\s+/g, '');
    let purchaseData = pageData ? pageData.purchaseData : [];
    function return_product_details(section_name, details_class_selector) {
        let product_details = "";
        $(".summary__header").filter(function() {
            return $(this).text().trim() == section_name;
        }).parents(".summary").siblings(".mp-card__list-item").find(details_class_selector).each(function() {
            product_details = product_details + $(this).text().trim().replace(/\s\s/g, "").replace(/- \(/, " - (") + ",";
        });
        return product_details.slice(0, -1);
    }
    let hash = CryptoJS.SHA256($('.order-confirmation__section .qf-text-legal span').text());
    let salesCode;
    if(data.salesCodeValue) {
        salesCode = data.salesCodeValue;
    }
    eventObj.customer = {currentProduct: return_product_details('Existing products', '.summary__item-name')};
    eventObj.customer.currentPrice = return_product_details('Existing products', '.summary__item-price');
    eventObj.customer.emailHash = hash.toString(CryptoJS.enc.Hex);
    eventObj.customer.newProduct = return_product_details('New products', '.summary__item-name');
    eventObj.customer.newPrice = return_product_details('New products', '.summary__item-price');
    eventObj.customer.monthlyTotal = $('.qf-sections__section.monthly').text().replace(/\s\s/g, '').replace(/.*\$/, '$').replace(/[a-zA-Z].*/, '');
    eventObj.customer.orderId = $('#pageInfo').attr('data-page-info').split(',')[1].split(":").pop().replace(/\D/g, '');
    eventObj.customer.company = data.company ==='yes'? data.company: 'no';
    eventObj.salesCode = salesCode;
    eventObj.ecommerce = {
        purchase: {
            actionField: {
                id: bftstrans,
                revenue: purchaseData.revenue
            },
            products: purchaseData.products
        }
    };
    dataLayer.push(eventObj);
}
function guestwifishow(ev, data) {
    let dataId=data.id;
    let instantchecker='N/A';
    if((dataId ==='instant-internet-signup-btn')||( dataId ==='customerinternet')||( dataId ==='manageinternet')){
        instantchecker='quantum-instant internet';
    }
    let eventObj= {
        flow:{
            name:instantchecker==='N/A' ? 'quantum-instant wifi':instantchecker,
            step: 'mdu sign up'
        },
        event: 'mdu_click',

    };

    eventObj.mduButton=data.mduclick;
    dataLayer.push(eventObj);
}
function promocode(ev, data) {
    if(data.promocode){
        let eventObj = createEventObj({
            event: 'promo_code',
            step: 'promo code'
        });
        let promocodevar =data.promocode;
        eventObj.promoCode = promocodevar;
        dataLayer.push(eventObj);
    }
}
function getFlowName() {
    flow_name = window.localStorage.getItem('flow');
    window.removeEventListener('Flow Name Set', getFlowName);
    initDatalayer();
}
function accnccid(ev, data) {
    let accountId =data.acid.replace(/\s+/g, '');
    let contactId =data.ccid.replace(/\s+/g, '');
    if(data){

        let eventObj = {
            page: {
                pageType: 'quantum',
                pageName: pageTitle
            },
            customer: {
            }
        };

        eventObj.customer.accountId = accountId;
        eventObj.customer.contactId = contactId;
        dataLayer.push(eventObj);

    }
}

/**
 * changeSubscriptionProduct
 * @param {Object} event jQuery.Event object.
 * @param {Object|Array} args Additional parameters to pass along to the event handler.
 */
function changeSubscriptionProduct(event, args) {
    let productName = args.productName;
    let eventObj = createEventObj({
        event: 'qf_start_remove_subscription',
        flow_name: 'quantum - ec',
        step: 'start remove subscription'
    });

    eventObj.removedSubscription = productName;
    dataLayer.push(eventObj);
}

/**
 * startChangeProduct
 */
function startChangeProduct() {
    let eventObj = createEventObj({
        event: 'qf_start_change_product',
        flow_name: 'quantum - ec',
        step: 'start change product'
    });

    let $mpCard = $(this).closest('.mp-card');
    if ($mpCard.length) {
        let currentProduct = $mpCard.find('.mp-account__list  .mp-account__list-item-header').text().trim().replace(/\n/g, '');
        let currentPrice = $mpCard.find('.mp-account__list .mp-account__list-item-value').text().trim().replace(/\n/g, '');
        eventObj.customer = {
            currentProduct: currentProduct,
            currentPrice: currentPrice
        };
    }

    dataLayer.push(eventObj);
}
/* ============================== My Portal ============================== */
/**
 * mpChangeAppointment
 */
function mpChangeAppointment() {
    let eventObj = createEventObj({
        event: 'qf_change_appointment_success',
        flow_name: 'quantum - ec',
        step: 'confirm change appointment'
    });
    dataLayer.push(eventObj);
}
/**
 * mpChangeResumeDateDone
 */
function mpChangeResumeDateDone() {
    let eventObj = createEventObj({
        event: 'qf_change_resume_service_success',
        flow_name: 'quantum - ec',
        step: 'pause service'
    });
    dataLayer.push(eventObj);
}

/**
 * mpCancelSubscriptionDone
 * @param {Object} event jQuery.Event object.
 * @param {Object|Array} args Additional parameters to pass along to the event handler.
 */
function mpCancelSubscriptionDone(event, args) {
    let redirectUrl = args.redirectUrl;
    let eventObj = createEventObj({
        event: 'qf_cancel_subscription_success',
        flow_name: 'quantum - ec',
        step: 'confirm cancel subscription'
    });
    // Always add the eventTimeout when using eventCallback. The former takes a numerical value as its parameter,
    // representing the number of milliseconds to wait before calling eventCallback anyway.
    // In other words, even if your Tags stall and never signal completion, after two seconds eventCallback is invoked.
    eventObj.eventTimeout = 2000;
    eventObj.eventCallback = function() {
        window.location = redirectUrl;
    };
    dataLayer.push(eventObj);
}

/**
 * mpResumeSubscription
 * @param {Object} event jQuery.Event object.
 * @param {Object|Array} args Additional parameters to pass along to the event handler.
 */
function mpResumeSubscription(event, args) {
    let redirectUrl = args.redirectUrl;
    let eventObj = createEventObj({
        event: 'qf_resume_service_success',
        flow_name: 'quantum - ec',
        step: 'resume service'
    });
    eventObj.eventTimeout = GTM_EVENT_TIMEOUT;
    eventObj.eventCallback = function() {
        window.location = redirectUrl;
    };
    dataLayer.push(eventObj);
}

/**
 * mpChangeResumeDateLink
 * @param {Object} event jQuery.Event object.
 * @param {Object|Array} args Additional parameters to pass along to the event handler.
 */
function mpChangeResumeDateLink(event, args) {
    let redirectUrl = args.targetUrl;
    let eventObj = createEventObj({
        event: 'qf_change_resume_service_start',
        flow_name: 'quantum - ec',
        step: 'pause service'
    });
    eventObj.eventTimeout = GTM_EVENT_TIMEOUT;
    eventObj.eventCallback = function() {
        window.location = redirectUrl;
    };
    dataLayer.push(eventObj);
}

/**
 * mpPasswordChange
 */
function mpPasswordChange() {
    let eventObj = createEventObj({
        event: 'qf_password_reset_success',
        flow_name: 'quantum - ec',
        step: 'password change'
    });
    dataLayer.push(eventObj);
}

/**
 * mpAddDevice
 */
function mpAddDevice() {
    let eventObj = createEventObj({
        event: 'qf_add_network_device_success',
        flow_name: 'quantum - ec',
        step: 'add network device'
    });
    dataLayer.push(eventObj);
}

/**
 * mpRemoveDevice
 */
function mpRemoveDevice() {
    let eventObj = createEventObj({
        event: 'qf_remove_network_device_success',
        flow_name: 'quantum - ec',
        step: 'remove network device'
    });
    dataLayer.push(eventObj);
}

/**
 * cancelChangeOrderSuccess
 * @param {Object} event jQuery.Event object.
 * @param {Object|Array} args Additional parameters to pass along to the event handler.
 */
function cancelChangeOrderSuccess(event, args) {
    let orderProduct = args.orderProduct;
    let eventObj = createEventObj({
        event: 'qf_cancel_order_success',
        flow_name: 'quantum - ec',
        step: 'confirm cancel order'
    });
    eventObj.orderProduct = orderProduct;
    dataLayer.push(eventObj);
}

/**
 * mpAccountInfoUpdate
 * @param {Object} event jQuery.Event object.
 * @param {Object|Array} args Additional parameters to pass along to the event handler.
 */
function mpAccountInfoUpdate(event, args) {
    let eventObj = createEventObj({
        event: 'qf_ec_account_info_success',
        flow_name: 'quantum - ec'
    });
    eventObj.messageDisplayed = args.messageDisplayed;
    dataLayer.push(eventObj);
}

/**
 * pauseServiceOrderDone
 */
function pauseServiceOrderDone() {
    let eventObj = createEventObj({
        event: 'qf_pause_service_success',
        flow_name: 'quantum - ec',
        step: 'pause service'
    });
    dataLayer.push(eventObj);
}

/**
 * cancelPauseOrderLink -  ESS-237 Cancel pause order
 * @param {Object} event jQuery.Event object.
 * @param {Object|Array} args Additional parameters to pass along to the event handler.
 */
function cancelPauseOrderLink(event, args) {
    let redirectUrl = args.targetUrl;
    let eventObj = createEventObj({
        event: 'qf_cancel_pause_service_start',
        flow_name: 'quantum - ec',
        step: 'pause service'
    });
    eventObj.eventTimeout = GTM_EVENT_TIMEOUT;
    eventObj.eventCallback = function() {
        window.location = redirectUrl;
    };
    dataLayer.push(eventObj);
}

/**
 * removeProduct360
 * @param {Object} event jQuery.Event object.
 * @param {Object|Array} args Additional parameters to pass along to the event handler.
 */
function removeProduct360(event, args) {
    let redirectUrl = args.redirectUrl;
    let eventObj = createEventObj({
        event: 'qf_cancel_product_success',
        flow_name: 'quantum - ec',
        step: 'cancel product'
    });
    eventObj.eventTimeout = GTM_EVENT_TIMEOUT;
    eventObj.eventCallback = function() {
        window.location = redirectUrl;
    };
    dataLayer.push(eventObj);
}

/**
 * communicationPrefUpdated
 */
function communicationPrefUpdated() {
    let eventObj = createEventObj({
        event: 'qf_ec_comm_info_success',
        flow_name: 'quantum - ec'
    });
    dataLayer.push(eventObj);
}

/**
 * changeWifiPassword
 */
function changeWifiPassword() {
    let eventObj = createEventObj({
        event: 'qf_change_wifi_password_success',
        flow_name: 'quantum - ec',
        step: 'change wifi password'
    });
    dataLayer.push(eventObj);
}

/**
 * cancelPausedOrderConfirm - ESS-248 Cancel pause order - confirm
 */
function cancelPausedOrderConfirm() {
    let eventObj = createEventObj({
        event: 'qf_cancel_pause_service_confirm',
        flow_name: 'quantum - ec',
        step: 'pause service'
    });
    dataLayer.push(eventObj);
}

/**
 * cancelPausedOrderSuccess - ESS-256 cancel pause order - success
 * @param {Object} event jQuery.Event object.
 * @param {Object|Array} args Additional parameters to pass along to the event handler.
 */
function cancelPausedOrderSuccess(event, args) {
    let redirectUrl = args.redirectUrl;
    let eventObj = createEventObj({
        event: 'qf_cancel_pause_service_success',
        flow_name: 'quantum - ec',
        step: 'pause service'
    });
    eventObj.eventTimeout = GTM_EVENT_TIMEOUT;
    eventObj.eventCallback = function() {
        window.location = redirectUrl;
    };
    dataLayer.push(eventObj);
}

/* ============================== Common Solutions ============================== */
function supportCenterPush(){
    window.dataLayer.push({
        event: 'qf_fp_common_solutions_click',
        buttonName: 'Support Center'
    });
}

function manageAccountPush(){
    window.dataLayer.push({
        event: 'qf_fp_common_solutions_click',
        buttonName: 'Manage Account'
    });
}

function mySettingsPush(){
    window.dataLayer.push({
        event: 'qf_fp_common_solutions_click',
        buttonName: 'My Settings'
    });
}

function changePaymentMethodPush(){
    window.dataLayer.push({
        event: 'qf_fp_common_solutions_click',
        buttonName: 'Change Payment Method'
    });
}

/* ============================== Change Subscription ============================== */

function changeSubscriptionPush(){
    window.dataLayer.push({
        'event': 'qf_fp_change subscription'
    });
}

/* ============================== View Payments ============================== */

function viewPaymentsPush(){
    window.dataLayer.push({
        event: 'qf_fp_view_payments'
    });
}

/* ============================== Update Payment Method ===================== */

function updateCCPaymentMethodPush(){
    window.dataLayer.push({
        event: 'qf_fp_update_payment_method',
        buttonName: 'credit card'
    });
}

function updatePayPalPaymentMethodPush(){
    window.dataLayer.push({
        event: 'qf_fp_update_payment_method',
        buttonName: 'pay pal'
    });
}

function updaeACHPaymentMethodPush(){
    window.dataLayer.push({
        event: 'qf_fp_update_payment_method',
        buttonName: 'ach'
    });
}

function updatePaymentZuoraIFramePush(){
    window.dataLayer.push({
        event: 'qf_fp_pay_account_balance'
    });
}

function updatePaymentMethodModalPush(){
    window.dataLayer.push({
        event: 'qf_fp_continue_to_payment'
    });
}

/* ============================== MPAccount Review Cancel===================== */

function reviewCancelClickCancelButton(event, args) {
    let redirectUrl = args.targetUrl;
    let eventObj = createEventObj({
        event: 'qf_ec_cancel_service_click',
        flow_name: 'quantum - ec'
    });
    eventObj.buttonName = 'cancel';
    eventObj.eventTimeout = GTM_EVENT_TIMEOUT;
    eventObj.eventCallback = function() {
        window.location = redirectUrl;
    };
    dataLayer.push(eventObj);
}

function reviewCancelFormSubmit() {
    let eventObj = createEventObj({
        event: 'qf_ec_cancel_service_click',
        flow_name: 'quantum - ec'
    });
    eventObj.buttonName = 'continue';
    dataLayer.push(eventObj);
}

function reviewCancelClickPhoneNumber(event, args) {
    let redirectUrl = args.targetUrl;
    let eventObj = createEventObj({
        event: 'qf_ec_cancel_service_click',
        flow_name: 'quantum - ec'
    });
    eventObj.buttonName = 'click to call';
    eventObj.eventTimeout = GTM_EVENT_TIMEOUT;
    eventObj.eventCallback = function() {
        window.location = redirectUrl;
    };
    dataLayer.push(eventObj);
}

function cancelSubscriptionReasonSelect(event, args) {
    let cancellationReason = args.cancellationReason;
    if (cancellationReason === undefined) return;
    let eventObj = createEventObj({
        event: 'qf_ec_cancellation_reason',
        flow_name: 'quantum - ec'
    });
    eventObj.cancellationReason = cancellationReason;
    dataLayer.push(eventObj);
}

function cancelSubscriptionBtnClick(event, args) {
    let buttonName = args.buttonName;
    if (buttonName === undefined) return;
    let eventObj = createEventObj({
        event: 'qf_ec_cancel_service_click',
        flow_name: 'quantum - ec'
    });
    eventObj.buttonName = buttonName;
    dataLayer.push(eventObj);
}

function pushDataLayerEvent(event, args) {
    if (args.event === undefined) return;
    let eventObj = {
        page: {
            pageType: args.pageType ? args.pageType : 'quantum',
            pageName: pageTitle
        },
        event: args.event,
        flow: {
            name: args.flowName ? args.flowName : 'quantum - ec',
            step: args.flowStep || ''
        }
    };
    if (args.buttonName !== undefined) {
        eventObj.buttonName = args.buttonName;
    }
    if (args.messageDisplayed !== undefined) {
        eventObj.messageDisplayed = args.messageDisplayed;
    }
    if (args.accountType !== undefined) {
        eventObj.accountType = args.accountType;
    }
    if (args.installDate !== undefined) {
        eventObj.installDate = args.installDate;
    }
    if (args.resumeDate !== undefined) {
        eventObj.resumeDate = args.resumeDate;
    }
    if (args.customer !== undefined) {
        eventObj.customer = {};
        if (args.customer.accountId) {
            eventObj.customer.accountId = $('#useraccountId').val();
        }
    }
    dataLayer.push(eventObj);
}

function cancelConfirmClickCancel(event, args) {
    let redirectUrl = args.targetUrl;
    let eventObj = createEventObj({
        event: 'qf_ec_cancel_service_confirm',
        flow_name: 'quantum - ec'
    });
    eventObj.buttonName = 'cancel';
    eventObj.eventTimeout = GTM_EVENT_TIMEOUT;
    eventObj.eventCallback = function() {
        window.location = redirectUrl;
    };
    dataLayer.push(eventObj);
}

function retentionOfferBtnClick(event, args) {
    let buttonName = args.buttonName;
    let offerName = args.offerName;
    if (buttonName === undefined || offerName === undefined) {
        return;
    }
    let eventObj = createEventObj({
        event: 'qf_ec_retention_offer',
        flow_name: 'quantum - ec'
    });
    eventObj.buttonName = buttonName;
    eventObj.offerName = offerName;
    dataLayer.push(eventObj);
}

function retentionOfferSummaryBtnClick(event, args) {
    let buttonName = args.buttonName;
    if (buttonName === undefined) {
        return;
    }
    let eventObj = createEventObj({
        event: 'qf_ec_retention_click',
        flow_name: 'quantum - ec'
    });
    eventObj.buttonName = buttonName;
    dataLayer.push(eventObj);
}

function headerMenuListItemClick(event, args) {
    let buttonName = args.buttonName;
    if (buttonName === undefined) return;
    let eventObj = {
        page: {
            pageType: 'quantum',
            pageName: pageTitle
        },
        event: 'qf_navigation_bar_click',
        flow: {
            name: 'quantum - ec',
            step: 'qf navigation bar click'
        },
        buttonName: buttonName
    };
    dataLayer.push(eventObj);
}

function cancelConfirmClickConfirm() {
    let eventObj = createEventObj({
        event: 'qf_ec_cancel_service_confirm',
        flow_name: 'quantum - ec'
    });
    eventObj.buttonName = 'continue';
    dataLayer.push(eventObj);
}

function serviceWihtOutDisconnect(ev, data) {
    let eventObj = {
        event: 'qf_contactus_prompt',
        contactusMessage: 'contact us to verify occupancy'
    };
    dataLayer.push(eventObj);
}

/* ============================== Affordable Connectivity Program Static Page ===================== */

function applyNowPush() {
    let eventObj = createEventObj({
        event: 'qf_acp_apply_now_click',
        step: 'acp apply now'
    });

    dataLayer.push(eventObj);
}

/* ============================== Appointment Details ===================== */

function appointmentDetailsBtnPageClick(event, args) {
    let buttonName = args.buttonName;
    if (buttonName === undefined) return;
    let eventObj = createEventObj({
        event: 'qf_ec_appt_scheduled_click',
        flow_name: 'quantum - ec',
        step: 'qf appt scheduled click'
    });
    eventObj.buttonName = buttonName;
    dataLayer.push(eventObj);
}

function appointmentSupportModalClick(event, args) {
    let buttonName = args.buttonName;
    if (buttonName === undefined) return;
    let eventObj = createEventObj({
        event: 'qf_appt_support_modal_click',
        flow_name: 'quantum - ec',
        step: 'appt support modal click'
    });
    eventObj.buttonName = buttonName;
    dataLayer.push(eventObj);
}

function appointmentRescheduleActionStart(event, args) {
    let earliestInstall = args.earliestInstall;
    let installDate = args.installDate;
    let appointmentNumber = args.saId;

    if (earliestInstall === undefined) return;
    if (installDate === undefined) return;

    let eventObj = createEventObj({
        event: 'qf_start_change_appointment',
        flow_name: 'quantum - ec',
        step: 'start change appt'
    });
    eventObj.earliestInstall = earliestInstall;
    eventObj.installDate = installDate;
    eventObj.appointmentNumber = appointmentNumber;

    dataLayer.push(eventObj);
}

function appointmentRescheduleActionEndSuccess(event, args) {
    let installDate = args.installDate;

    if (installDate === undefined) return;

    let eventObj = createEventObj({
        event: 'qf_change_appointment_success',
        flow_name: 'quantum - ec',
        step: 'change appt success'
    });

    eventObj.installDate = installDate;
    dataLayer.push(eventObj);
}

function appointmentRescheduleActionFailure(event, args) {
    let eventObj = createEventObj({
        event: 'qf_change_appt_fail',
        flow_name: 'quantum - ec',
        step: 'change appt failure'
    });

    dataLayer.push(eventObj);
}

function connectedDeviceLoadError () {
    let eventObj = createEventObj({
        event: 'qf_ec_cd_error',
        flow: { name: 'quantum - ec' }
    });
    dataLayer.push(eventObj);
}

// *************************CFMigration Leads**************************
function doubleCheckOnload() {
    window.dataLayer.push({
        event: 'qf_migration_verify_page',
        flow:{
            name: flow_name
        },
    });
}

function doubleCheckContinueBtnEmailOrPhone() {
    const gtmData = $('[name=doubleCheckCf]:checked').data();
    let buttonName = gtmData.set;
    window.dataLayer.push({
        event: 'qf_migration_acct_verification',
        flow:{
            name: flow_name
        },
        buttonName:buttonName
    });
}

function doubleCheckContinueBtnAccountNum(event, buttonName) {
    window.dataLayer.push({
        event: 'qf_migration_acct_verification',
        flow:{
            name: flow_name
        },
        buttonName:buttonName
    });
}
/* ============================== Need Differrent Options ============================== */
function needDifferentOptions () {
    let eventObj = createEventObj({
        event: 'qf_migration_need_different_options',
        step: 'qf migration need different options',
    });
    eventObj.buttonName = 'need different options';
    dataLayer.push(eventObj);
}

/* ============================== Need Differrent Options Back ============================== */
function needDifferentOptionsBack () {
    let eventObj = createEventObj({
        event: 'qf_migration_different_options_click',
        step: 'qf migration need different options click back',
    });
    eventObj.buttonName = 'back';
    dataLayer.push(eventObj);
}
/* ============================== Need Differrent Options Continue ============================== */
function needDifferrentOptionsContinue () {
    let eventObj = createEventObj({
        event: 'qf_migration_different_options_click',
        step: 'qf migration need different options click continue',
    });
    eventObj.buttonName = 'continue';
    dataLayer.push(eventObj);
}
/* ============================== Need Differrent Options Close ============================== */
function needDifferrentOptionsClose () {
    let eventObj = createEventObj({
        event: 'qf_migration_different_options_click',
        step: 'qf migration need different options click close',
    });
    eventObj.buttonName = 'close';
    dataLayer.push(eventObj);
}

/* ============================== Migration Did not work show ============================== */
function didNotWorkModelShow () {
    let eventObj = createEventObj({
        event: 'qf_migration_didnt_work',
        step: 'qf migration didnt work',
    });

    eventObj.buttonName = 'didnt work';
    dataLayer.push(eventObj);
}

/* ============================== Migration Did not work click ============================== */
function didNotWorkModelBack () {
    let eventObj = createEventObj({
        event: 'qf_migration_didnt_work_click',
        step: 'qf migration didnt work click back',
    });

    eventObj.buttonName = 'back';
    dataLayer.push(eventObj);
}

function didNotWorkModelContinue () {
    let eventObj = createEventObj({
        event: 'qf_migration_didnt_work_click',
        step: 'qf migration didnt work click continue',
    });

    eventObj.buttonName = 'continue';
    dataLayer.push(eventObj);
}

function didNotWorkModelClose () {
    let eventObj = createEventObj({
        event: 'qf_migration_didnt_work_click',
        step: 'qf migration didnt work click close',
    });

    eventObj.buttonName = 'close';
    dataLayer.push(eventObj);
}

/* ============================== Migration One time code verify show ============================== */
function oneTimeCodeVerify () {
    let eventObj = createEventObj({
        event: 'qf_migration_one_time_code_verify',
        step: 'migration one time code show',
    });

    dataLayer.push(eventObj);
}

function onboardingBannerDisplay () {
    let eventObj = createEventObj({
        event: 'qf_screen_interaction'
    });

    Object.assign(eventObj, {
        app_section: 'Dashboard',
        screen_name: 'Qunatum Fiber',
        interaction_section: 'Banner',
        interaction_name: 'info for new customers',
        customer_type: 'Newly migrated customer'
    });

    dataLayer.push(eventObj);
}

function onboardingBannerLinkClick () {
    let eventObj = createEventObj({
        event: 'qf_screen_interaction'
    });

    Object.assign(eventObj, {
        interaction_section: 'new customer info',
        interaction_type: 'Link',
        interaction_name: 'for new customer info',
        customer_type: 'Newly migrated customer'
    });

    dataLayer.push(eventObj);
}

function onboardingModalLoad () {
    let eventObj = createEventObj({
        event: 'qf_screen_view'
    });

    Object.assign(eventObj, {
        app_section: 'Quantum fiber banner',
        screen_name: 'Quantum Fiber',
        customer_type: 'Newly Migrated customers'
    });

    dataLayer.push(eventObj);
}

function onboardingModalClose () {
    let eventObj = createEventObj({
        event: 'qf_screen_interaction'
    });

    Object.assign(eventObj, {
        app_section: 'Quantum fiber banner',
        screen_name: 'Quantum Fiber',
        interaction_section: 'Header',
        interaction_type: 'button',
        interaction_name: 'X',
        customer_type: 'Newly Migrated customers'
    });

    dataLayer.push(eventObj);
}

//RecentMigratedBannerGTM Close//
function notificationOnLoad () {
    let eventObj = createEventObj({
        event: 'qf_screen_view'
    });

    Object.assign(eventObj, {
        app_section: 'Notification center',
        screen_name: 'Notification center',
        customer_type: 'Existing QF customer'
    });
    dataLayer.push(eventObj);
}

function notificationClickEvent () {
    let eventObj = createEventObj({
        event: 'qf_screen_interaction',
    });

    Object.assign(eventObj, {
        app_section: 'Notification center',
        screen_name: 'Quantum Fiber',
        interaction_section: 'Header',
        interaction_type: 'Button',
        interaction_name: 'Notification center'
    });
    dataLayer.push(eventObj);
}

function headerMenuMessageCenterClick () {
    let eventObj = createEventObj({
        event: 'qf_navigation_bar_click',
        step: 'qf navigation bar click',
    });

    eventObj.buttonName = 'message center';
    dataLayer.push(eventObj);
}

function headerMessageCenterLoad () {
    let eventObj = createEventObj({
        event: 'qf_screen_view'
    });

    Object.assign(eventObj, {
        app_section: 'Notification center',
        screen_name: 'Notification center',
        customer_type: 'Existing QF customer'
    });

    dataLayer.push(eventObj);
}
function headerSearchBarClick () {
    let eventObj = createEventObj({
        event: 'qf_ec_search_bar_click',
        flow_name: 'quantum - ec',
        step: 'qf search bar click'
    });
    dataLayer.push(eventObj);
}
function searchSuggestionLinkClick (event, args) {
    let link = args.linkClicked;
    let eventObj = createEventObj({
        event: 'qf_ec_frequently_accessed_links',
        flow_name: 'quantum - ec',
        step: 'qf frequently accessed links click'
    });
    eventObj.falSelect = link;
    dataLayer.push(eventObj);
}
function suspendedPageOnLoad (event,args) {
    let eventObj = createEventObj({
        flow_name: 'walled garden',
        step: 'payment process'
    });
    dataLayer.push(eventObj);
}
function inJeopardyPageOnLoad (event,args) {
    let eventObj = createEventObj({
        flow_name: 'walled garden',
        step: 'payment process - in-jeopardy'
    });
    dataLayer.push(eventObj);
}

function appointmentCancelGiftCardOfferModalLoad () {
    let eventObj = createEventObj({
        event: 'qf_screen_interaction'
    });

    Object.assign(eventObj, {
        app_section: 'Dashboard',
        screen_name: 'Gift card offer',
        interaction_section: 'Modal',
        interaction_name: 'Apologize for inconvenience',
        interaction_type: 'Customer cancelling his installation appointment'
    });

    dataLayer.push(eventObj);
}

function appointmentContinueCancelationBtnClick(event, args) {
    let eventObj = createEventObj({
        event: 'modal_interaction'
    });

    Object.assign(eventObj, {
        app_section: 'Dashboard',
        screen_name: 'Gift card offer',
        modal_type: 'custom',
        interaction_section: 'Continue Cancellation',
        interaction_type: 'Button',
        interaction_name: args.buttonName
    });

    dataLayer.push(eventObj);
}

function appointmentCancelGiftCardOfferAcceptBtnClick(event, args) {
    let eventObj = createEventObj({
        event: 'modal_interaction'
    });

    Object.assign(eventObj, {
        app_section: 'Dashboard',
        screen_name: 'Gift card offer',
        modal_type: 'custom',
        interaction_section: 'Accept Offer',
        interaction_type: 'Button',
        interaction_name: args.buttonName
    });

    dataLayer.push(eventObj);
}

function cvProductSelectionDetails (event, name) {
    let eventObj = createEventObj({
        event: 'product_click',
        step: 'with phone option',
    });
    eventObj.ecommerce = {
        click:{
            products: name
        }
    },
    dataLayer.push(eventObj);

}

/* ============================== Disconnect Banner ============================== */
function disconnectBannerPresent (event, args) {
    let eventObj = createEventObj({
        event: args.event,
    });
    eventObj.messageDisplayed =  args.messageDisplayed;
    dataLayer.push(eventObj);
}

function disconnectBannerLinkClick (event, args) {
    let eventObj = createEventObj({
        event: args.event,
    });
    eventObj.messageDisplayed =  args.messageDisplayed;
    eventObj.buttonName = args.buttonName;
    dataLayer.push(eventObj);
}

function equipmentPage (event, args) { 
    let eventObj = createEventObj({
        event: args.event,
    });
    dataLayer.push(eventObj);
}

function equipmentBtnClick (event, args) {
    let eventObj = createEventObj({
        event: args.event,
    });
    eventObj.buttonName = args.buttonName;
    dataLayer.push(eventObj);
}

function outageToolModal (event, args) {
    let eventObj = ({
        event: 'qf_ec_outage_modal',
        flow:{
            name: 'outage tool',
            step: args.step,
            messageDisplayed: args.messageDisplayed
        }
    });
    dataLayer.push(eventObj);
}

function outageToolModalClick (event, args) {
    let eventObj = ({
        event: 'qf_ec_outage_modal_click',
        flow:{
            name: 'outage tool',
            step: args.step,
            buttonName: args.buttonName
        }
    });
    dataLayer.push(eventObj);
}

function outageToolPage (event, args) {
    let eventObj = ({
        event: 'qf_ec_outage_page',
        flow:{
            name: 'outage tool',
            step: args.step,
            messageDisplayed: args.messageDisplayed
        }
    });
    dataLayer.push(eventObj);
}

function nutritionLabelClick (event, args) {
    let eventObj = ({
        event: 'qf_ec_nutrition_label_click',
        flow:{
            name: 'quantum - ec',
            step: 'subscription information'
        },
        buttonName: args.buttonName
    });
    dataLayer.push(eventObj);
}

module.exports = {
    initEvents: function () {
        $(document)
            .on('gtm:mp:changeAppointment', mpChangeAppointment) // ESS-228 Appt reschedule - success modal
            .on('gtm:mp:mpChangeResumeDateDone', mpChangeResumeDateDone)// ESS-229 Change resume date - success
            .on('gtm:mp:cancelSubscriptionDone', mpCancelSubscriptionDone) // ESS-235  cancel subscription flow and success
            .on('gtm:mp:changeResumeDateLink', mpChangeResumeDateLink) // ESS-231  Change resume date - link
            .on('gtm:mp:addDevice', mpAddDevice) // ESS-234 Add/Remove device success
            .on('gtm:mp:removeDevice', mpRemoveDevice) // ESS-234 Add/Remove device success
            .on('gtm:mp:passwordChange', mpPasswordChange)//ESS-255  password rest - success modal
            .on('gtm:mp:pauseServiceOrderDone', pauseServiceOrderDone) // ESS-236 Pause order - success
            .on('gtm:mp:cancelPauseOrderLink', cancelPauseOrderLink) // ESS-237 Cancel pause order
            .on('gtm:mp:accountInfoUpdate', mpAccountInfoUpdate)//ESS-238 Account info - success
            .on('gtm:mp:removeProduct360', removeProduct360)//ESS-244 Remove product 360 - success
            .on('gtm:mp:resumeSubscription', mpResumeSubscription) // ESS-245
            .on('gtm:mp:commPrefUpdated', communicationPrefUpdated) // ESS-246 Comm Pref - success
            .on('gtm:mp:changeWifiPassword', changeWifiPassword) // ESS-247 change wifi password
            .on('gtm:mp:cancelPausedOrderConfirm', cancelPausedOrderConfirm) // ESS-248 Cancel pause order - confirm
            .on('gtm:mp:cancelChangeOrderSuccess', cancelChangeOrderSuccess)//ESS-253 cancel order - success modal
            .on('gtm:mp:cancelPausedOrderSuccess', cancelPausedOrderSuccess)//ESS-256 cancel pause order - success
            .on('gtm:mp:removeProductFormSubmit', removeProductOnFormSubmit)
            .on('gtm:mp:cancelProductOrSubscriptionStart', cancelProductOrSubscriptionStart)
            .on('addresscheck:action', addressCheckAction)
            .on('pindrop:action', pinDropAction)
            .on('pindropmap:action', pinDropMapAction)
            .on('noservice:action', noServiceAction)
            .on('noservice:ctl', noServiceCtl)
            .on('notifyme:action', notifyMeAction)
            .on('gtm:mp:onboardingBannerDisplay', onboardingBannerDisplay)
            .on('gtm:mp:onboardingBannerLinkClick', onboardingBannerLinkClick)
            .on('gtm:mp:onboardingModalLoad', onboardingModalLoad)
            .on('gtm:mp:onboardingModalClose', onboardingModalClose)
            .on('search:showaddress', addressVerification)
            .on('search:show', productImpressions)
            .on('search:selectproduct', productSelect)
            .on('search:moreDetails', moreDetails)
            .on('search:action', productAction)
            .on('search:searchBarClick', headerSearchBarClick)
            .on('search:suggestionLinkClick', searchSuggestionLinkClick)
            .on('schedule:install', scheduleInstall)
            .on('schedule:activate', scheduleActive)
            .on('schedule:submit', scheduleSubmit)
            .on('schedule:activatesubmit', scheduleActiveSubmit)
            .on('orderReview:show', orderReviewShow)
            .on('orderReview:continue', orderReviewAction)
            .on('payment:submitpay', paymentSubmit)
            .on('payment:submitPayComplete', outstandingBalancePushResult)
            .on('confirmation:show', confirmationShow)
            .on('confirmation:nextsteps', confirmationNextSteps)
            .on('GuestWifi:show', guestwifishow)
            .on('promoconfirmination:show', promocode)
            .on('acnccid:show', accnccid)
            .on('gtm:mp:changeSubscriptionProduct', changeSubscriptionProduct)
            .on('gtm:mp:rcClickCancel', reviewCancelClickCancelButton)
            .on('gtm:mp:rcFormSubmit', reviewCancelFormSubmit)
            .on('gtm:mp:rcClickPhoneNumber', reviewCancelClickPhoneNumber)
            .on('gtm:mp:cancelConfirmClickCancel', cancelConfirmClickCancel)
            .on('gtm:mp:headerMenuListItemClick', headerMenuListItemClick)
            .on('gtm:mp:cancelSubscriptionReasonSelect', cancelSubscriptionReasonSelect)
            .on('gtm:mp:cancelSubscriptionBtnClick', cancelSubscriptionBtnClick)
            .on('gtm:mp:pushDataLayerEvent', pushDataLayerEvent)
            .on('gtm:mp:cancelConfirmClickConfirm', cancelConfirmClickConfirm)
            .on('gtm:mp:retentionOfferBtnClick', retentionOfferBtnClick)
            .on('gtm:mp:retentionOfferSummaryBtnClick', retentionOfferSummaryBtnClick)
            .on('ServiceWihtOutDisconnect:show', serviceWihtOutDisconnect)
            .on('gtm:mp:appointmentCancelGiftCardOfferModalLoad', appointmentCancelGiftCardOfferModalLoad)
            .on('gtm:mp:appointmentContinueCancelationBtnClick', appointmentContinueCancelationBtnClick)
            .on('gtm:mp:appointmentCancelGiftCardOfferAcceptBtnClick', appointmentCancelGiftCardOfferAcceptBtnClick)
            .on('gtm:mp:appointmentDetailsBtnPageClick', appointmentDetailsBtnPageClick)
            .on('gtm:mp:appointmentSupportModalClick', appointmentSupportModalClick)
            .on('gtm:mp:appointmentRescheduleActionStart', appointmentRescheduleActionStart)
            .on('gtm:mp:appointmentRescheduleActionEndSuccess', appointmentRescheduleActionEndSuccess)
            .on('gtm:mp:appointmentRescheduleActionFailure', appointmentRescheduleActionFailure)
            .on('gtm:CFM:doubleCheckOnload', doubleCheckOnload)
            .on('gtm:CFM:DoubleCheckContinueBtn', doubleCheckContinueBtnEmailOrPhone)
            .on('gtm:CFM:DoubleCheckContinueBtnAccountNum', doubleCheckContinueBtnAccountNum)
            .on('oneTimeCodeVerify:show', oneTimeCodeVerify)
            .on('gtm:mp:headerMenuMessageCenterClick', headerMenuMessageCenterClick)
            .on('gtm:mp:headerMessageCenterLoad', headerMessageCenterLoad)
            .on('gtm:mp:notificationOnLoad ', notificationOnLoad)
            .on('gtm:mp:notificationClickEvent', notificationClickEvent)
            .on('gtm:mp:connectedDeviceLoadError', connectedDeviceLoadError)
            .on('gtm:mp:suspendedAccPageLoad', suspendedPageOnLoad)
            .on('gtm:mp:inJeopardyAccPageLoad', inJeopardyPageOnLoad)
            .on('gtm:cv:continueWithphoneSelection', cvProductSelectionDetails)
            .on('gtm:cv:loadWithPhoneOption', cvPhoneOption)
            .on('gtm:cv:continueWithPhoneOption', cvAllProductDetails)
            .on('gtm:cv:continueWithoutphone', cvContinueWithoutphone)
            .on('gtm:mp:disconnectBannerPresent', disconnectBannerPresent)
            .on('gtm:mp:disconnectBannerLinkClick', disconnectBannerLinkClick)
            .on('gtm:mp:equipmentPage', equipmentPage)
            .on('gtm:mp:equipmentBtnClick', equipmentBtnClick)
            .on('gtm:mp:outageToolModal', outageToolModal)
            .on('gtm:mp:outageToolModalClick', outageToolModalClick)
            .on('gtm:mp:outageToolPage', outageToolPage)
            .on('gtm:mp:nutritionLabelClick', nutritionLabelClick);

        $('#notify_success__find_plan').on('click', notifySuccessAction);
        $('.js-view-invoice').on('click', viewInvoicePush);
        $('.mp-card__savepayment-footer-link').on('click', updatePaymentMethodPush);
        $('#submit-preferences').on('click', comPrefPush);
        $('#submit-account-info').on('click', accountinfoPush);
        $('.js-edit-personal-info').on('click', accountEditInfoPush);
        $('.mp-account__btn-group .qf-btn-primary-action:contains("Manage products")').on('click', manageProductPush);
        $('.mp-account__btn-group .qf-btn-secondary:contains("Cancel Subscription")').on('click', cancelSubPush);
        $('a.support__link:contains("Sign In")').on('click', hSignInPush);
        $('.mp-account__btn-group button:contains("Resume subscription")').on('click', resumeSubPush);
        $('a.mp-network__remove-device').on('click', removeDeviceNetwork);
        $('#resume-subscription-submit-btn').on('click', resumeServicePush);
        $('.js-cancel-pending').on('click', cancelPendingOrderPush);
        $('.js-CancelPending-btn').on('click', confirmCancelPush);
        $('#mp-settings-submit-pw-edit').on('click', passwordChangePush);
        $('.mp-shop__trial-btn').on('click', tvServiceClickPush);
        $('#submit-device').on('click', addDevicePush);
        $('#form-edit-device').on('click', removeDevicePush);
        $('#submit-wifi-password').on('click', ChangeWifiPush);
        $('.change-btn').on('click', rescheduleAppPush);
        $('#mp-appointment-reschedule').on('click', rescheduleAppPushConfirm);
        $('.submit-cancel-appt').on('click', rescheduleAppPushConfirm);
        $('.mp-service-support__card-item .mp-service-support__link, .helpButton button').on('click', contactUsPush);
        $('#mp-pause__submit').on('click', pauseServicePush);
        $('.mp-shop__battery').on('click', batteryButtonPush);
        $("#internet").find(".js-link-change-subscription").on('click', function(event) {
            event.preventDefault();
            let productAbbr = $('.mp-manage__content-tab.active .mp-card .mp-account__list-item-header')
                .first().text().trim().replace(/\n/g, '');
            $('body').trigger('gtm:mp:removeProductFormSubmit', { productAbbr: productAbbr } );
            window.location = event.target.href;
        });
        $('.jsConnectedVoice').on('click', connectedVoicePush);
        $('.select-plan-btn').on('click', changeSpeedPush);
        $('#mp-shop__change').on('click', continueChangePush);
        $('#pay-submit').on('click', confirmChangePush);
        if ($(".breadcrumb-item").last().text().trim() == "Review Cancel") {
            $('.js-refresh-btn').on('click', removeSubPush);
        }
        $('.js-mpmanage-voice-remove-product').on('click', startChangeProduct);

        $('#support-center').on('click', supportCenterPush);
        $('#manage-account').on('click', manageAccountPush);
        $('#my-settings').on('click', mySettingsPush);
        $('#change-payment-method').on('click', changePaymentMethodPush);
        $('#change-subscription').on('click', changeSubscriptionPush);
        $('#view-payments').on('click',viewPaymentsPush);
        $('#mp-pay').on('click', updateCCPaymentMethodPush);
        $('#mp-paypal').on('click', updatePayPalPaymentMethodPush);
        $('#mp-ACHpayment').on('click', updaeACHPaymentMethodPush);
        $('#update-payment-method').on('click', updatePaymentMethodModalPush);
        $('#submitZuoraIframe, #submitButton').on('click', updatePaymentZuoraIFramePush);
        $('.apply-for-acp .apply-now-push').on('click', applyNowPush);
        $('a.different-options:contains(Need different options)').on('click', needDifferentOptions);
        $('#needDifferrentOptions_back').on('click', needDifferentOptionsBack);
        $('.needDifferrentOptions_cont').on('click', needDifferrentOptionsContinue);
        $('#needDifferrentOptions_close').on('click', needDifferrentOptionsClose);

        $('a.different-options:contains(Didn)').on('click', didNotWorkModelShow);
        $('#didNotWork_back').on('click', didNotWorkModelBack);
        $('.didNotWork_cont').on('click', didNotWorkModelContinue);
        $('#didNotWork_close').on('click', didNotWorkModelClose);

        if (window.location.pathname.indexOf('MPPayments-Show') > -1 ||
            /^\/shop\/submit\/?$/.test(window.location.pathname)) {
            if ($('#isAccountSuspended').val() === "true" || $('#isAccountInJeopardy').val() == "true") {
                window.dataLayer.push({
                    event: 'qf_fp_payment_successful'
                });
            } else {
                window.dataLayer.push({
                    event: 'qf_fp_update_payment_method_success'
                });
            }
        }

        if (
            // Development/Test URLs
            window.location.pathname.indexOf('AddressChecker-Show') > -1 ||
            window.location.href.indexOf('InternetPlan') > -1 ||
            // Production URLs
            /^\/shop\/?$/.test(window.location.pathname) ||
            /^\/shop\/speeds\/?$/.test(window.location.pathname) ||
            /^\/shop\/smartNidSpeeds\/?$/.test(window.location.pathname) ||
            // Development/Test/Production URLs
            window.location.pathname.indexOf('Instant-ShowInternetHome') > -1 ||
            window.location.pathname.indexOf('Instant-ShowWifi') > -1 ||
            window.location.pathname.indexOf('Instant-ExpiredGuest') > -1 ||
            window.location.pathname.indexOf('Instant-ShowGuestWifi') > -1 ||
            $('.fluidpage .custom-fluid').length > 0
        ) {
            window.addEventListener('Flow Name Set', getFlowName);
        } else {
            initDatalayer();
        }
    }
};
