'use strict';

const cache = {
    $qfErrorModal: $('#qf-modal-error'),
    $refreshBtn: $('#qf-modal-error .js-refresh-btn'),
    $refreshBtn2: $('#qf-modal-lead-creationError-refresh-btn')
};


module.exports = {
    initEvents: function () {
        // Add refresh functionality to the refresh button
        cache.$refreshBtn.on('click', function () {
            location.reload();
        });
        cache.$refreshBtn2.on('click', function () {
            location.reload();
        });

        // Stops spinner when modal closes
        cache.$qfErrorModal.on('show.bs.modal', function () {
            $.spinner().stop();
        });

        // Set timeout across the board
        $.ajaxSetup({
            timeout: cache.$qfErrorModal.data('failed-to-load-timeout')
        });

        $('#qf-modal-error-lead').modal('show');
    }
};
