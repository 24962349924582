'use strict';

/**
* @function
* @description Show blue outline border on button,input,select & textarea on Tab key press.
*/
exports.init = function () {
    /**
    * @function
    * @description Adds the .user-is-tabbing class which will add the outline on button, input, select & textarea on key press.
    */
    function handleTabEvent() {
        $('body').addClass('user-is-tabbing');
    }
    /**
    * @function
    * @description Removes the .user-is-tabbing class which will remove the outline on button, input, select & textarea on mouse down.
    */
    function handleMouseEvent() {
        $('body').removeClass('user-is-tabbing');
    }
    $(window).on('keydown', handleTabEvent);
    $(window).on('mousedown', handleMouseEvent);
};
