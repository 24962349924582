'use strict';

const cache = {
    $tokenVal: $('#csrf-token').val(),
    $firstName: $('#fname'),
    $lastName: $('#lname'),
    $businessName: $('#bname'),
    $qfModalError: $('#qf-modal-error'),
    $paymentTypes: $('#payment-types.payment__type-wrapper a'),
    $addressObj: $('#address-obj'),
    $phone: $('#phone'),
    $email: $('#email'),
    $userDetect: $('.payment__userdetect'),
    $pleaseWaitForOrderModal: $('#pleaseWaitForOrderModal'),
    processSuccess: false

};

const parseCookie = (cookie, newData) => {
    let attributes = cookie.split('~');
    let attributeObject = {};
    attributes.map((attribute) => {
        let obj = attribute.split('|');
        attributeObject[obj[0].trim()] = obj[1].trim();
        return attribute;
    });
    let newDataKeys = Object.keys(newData);
    newDataKeys.map((newKey) => {
        attributeObject[newKey] = newData[newKey];
        return newKey;
    });
    attributeObject[Object.keys(newData)[0]] = newData[Object.keys(newData)[0]];
    let newCookie = '';
    let keys = Object.keys(attributeObject);
    keys.map((key) => {
        newCookie += `${key}|${attributeObject[key]} ~ `;
        return key;
    });
    newCookie = newCookie.substring(0, newCookie.length - 2);
    return newCookie;
};
cache.$paymentTypes.on('click', function () {
    $(this).addClass('selected').siblings().removeClass('selected');
    let countrycode = $('span.service-address__no-service').text().split(',')[1].slice(1, 3);
    let zipcode = $('span.service-address__no-service').text().split(',')[1].slice(4, 9);
    let customerType = $('#checkBussiness').is(':checked');
    let cookie2 = localStorage.getItem('QFECKNOWME');
    let newcookie2 = parseCookie(cookie2, { GeographicState: countrycode, Zipcode: zipcode, Customertype: customerType ? 'SMB' : 'RES' });
    localStorage.setItem('QFECKNOWME', `${newcookie2}`);
});

$('#paypal').on('click', function () {
    $(this).trigger('payment:submitpay', { company: cache.$businessName.val() ? 'yes' : 'no' });  // tracking
    let countrycode = $('span.service-address__no-service').text().slice(38, 40);
    let zipcode = $('span.service-address__no-service').text().slice(40, 46);
    let customerType = $('#checkBussiness').is(':checked');
    let cookie2 = localStorage.getItem('QFECKNOWME');
    let newcookie2 = parseCookie(cookie2, { GeographicState: countrycode, Zipcode: zipcode, Customertype: customerType ? 'SMB' : 'RES' });
    localStorage.setItem('QFECKNOWME', `${newcookie2}`);
});

(function ($) { // eslint-disable-line
    $(document).on('click', '#pay , #paypal , #payach', function (e) {
        e.preventDefault();
        $('.ACHOptOut_Checkbox').addClass('d-none');
        $.spinner().start();
        cache.$firstName.val($.trim(cache.$firstName.val()));
        cache.$lastName.val($.trim(cache.$lastName.val()));
        cache.$firstName.submit();
        cache.$lastName.submit();
        $('#z_hppm_iframe').remove();
        var url = $(this).attr('href');
        let type;
        let pass;
        switch ($(this).attr('id')) { // eslint-disable-line
            case 'pay':
                type = 'CC';
                pass = true;
                break;
            case 'payach':
                type = 'ACH';
                pass = true;
                break;
            case 'paypal':
                type = 'PayPal';
                pass = $('.ACHOptOut_Checkbox').length === 0 || $('#ACHOptOutCheckbox').is(':checked');
                break;
            // TODO QFCC: add 3rd PAY type and validate form
        }

        let payLoad = {
            csrf_token: cache.$tokenVal,
            type: type,
            firstName: cache.$firstName.val(),
            lastName: cache.$lastName.val(),
            businessName: cache.$businessName.val()
        };

        if (cache.$firstName.hasClass('is-invalid') || cache.$lastName.hasClass('is-invalid')) {
            $.spinner().stop();
            return;
        }

        // Prevents checkbox from showing and being checked if user selects ACH.
        if (type !== 'ACH') {
            $('.ACHOptOut_Checkbox').removeClass('d-none');
        } else {
            $('#ACHOptOutCheckbox').prop('checked', false);
        }
        if (pass) {
            $.ajax({
                url: url,
                type: 'POST',
                data: payLoad,
                success: function (data) {
                    if (data.achEligibleFlow) {
                        if (data.displayACHProduct) {
                            $(`.summary__item[data-pid='C-ACH-Discount']`).removeClass('d-none'); // eslint-disable-line
                            var isPromoapplied = JSON.parse($('#isPromoapplied').val()); // eslint-disable-line
                            var discountPriceAfterPromo = $('#discountPriceMonthly').val();
                            var oneMonthPromo = JSON.parse($('#oneMonthPromo').val()); // eslint-disable-line
                            if (isPromoapplied && oneMonthPromo) { // eslint-disable-line
                                $('.due-post-promotion .summary__item .summary__item-total .summary__item-price').text(discountPriceAfterPromo);
                                $('.due-month .summary__item .summary__item-total .summary__item-price').text(discountPriceAfterPromo);
                            } else if (isPromoapplied && !oneMonthPromo) { // eslint-disable-line
                                $('.due-post-promotion .summary__item .summary__item-total .summary__item-price').text(discountPriceAfterPromo);
                                $('.due-month .summary__item .summary__item-total .summary__item-price').text(data.cartTotals.subTotals.default.formattedPriceShort);
                            } else {
                                $('.due-month .summary__item .summary__item-total .summary__item-price').text(data.cartTotals.subTotals.default.formattedPriceShort);
                            }
                        } else {
                            $(`.summary__item[data-pid='C-ACH-Discount']`).addClass('d-none'); // eslint-disable-line
                            var isPromoapplied = JSON.parse($('#isPromoapplied').val()); // eslint-disable-line
                            var oneMonthPromo = JSON.parse($('#oneMonthPromo').val()); // eslint-disable-line
                            if (isPromoapplied && oneMonthPromo) { // eslint-disable-line
                                $('.due-post-promotion .summary__item .summary__item-total .summary__item-price').text(data.cartTotals.originalSubTotals.Recurring.formattedPriceShort);
                                $('.due-month .summary__item .summary__item-total .summary__item-price').text(data.cartTotals.originalSubTotals.Recurring.formattedPriceShort);
                            } else if (isPromoapplied && !oneMonthPromo) { // eslint-disable-line
                                $('.due-post-promotion .summary__item .summary__item-total .summary__item-price').text(data.cartTotals.originalSubTotals.Recurring.formattedPriceShort);
                                $('.due-month .summary__item .summary__item-total .summary__item-price').text(data.cartTotals.subTotals.default.formattedPriceShort);
                            } else {
                                $('.due-month .summary__item .summary__item-total .summary__item-price').text(data.cartTotals.subTotals.default.formattedPriceShort);
                            }
                        }
                        $('.due-today .summary__item .summary__item-total .summary__item-price').text(data.cartTotals.subTotals.default.formattedPriceShort);
                    }
                    if (data.isOK === true) {
                        // The onloadCallBack function is invoked when the HPM iframe is loaded.
                        Z.setEventHandler('onloadCallback', function () { // eslint-disable-line
                            $.spinner().stop();
                        });
                        const addressObject = JSON.parse(cache.$addressObj.val() || '{}');
                        const prepopulateFields = {
                            creditCardCountry: 'USA',
                            creditCardState: addressObject.stateCodeLong || '',
                            creditCardCity: addressObject.city || '',
                            creditCardAddress1: addressObject.address1,
                            creditCardAddress2: addressObject.suite ? addressObject.suite : '',
                            creditCardPostalCode: addressObject.postalCode || '',
                            phone: cache.$phone.val() || '',
                            email: cache.$email.val() || '',
                            creditCardHolderName: `${payLoad.firstName} ${payLoad.lastName}`
                        };
                        // render CC Zoura
                        if ($('.ACHOptOut_Checkbox').length === 0 || $('#ACHOptOutCheckbox').is(':checked') || type === 'ACH') {
                            $('#ACHOptOutCheckbox').prop('checked', false);
                            $('.ACHOptOut_Checkbox').addClass('d-none');
                            Z.render( // eslint-disable-line
                                data,
                                prepopulateFields,
                                callback // eslint-disable-line
                            );
                        } else {
                            $.spinner().stop();
                            return;
                        }
                    } else {
                        // redirect to Paypal
                        window.location = data.url;
                    }
                },
                error: function (err) { // eslint-disable-line
                    $.spinner().stop();
                    $('#ACHOptOutCheckbox').prop('checked', false);
                    if (type !== 'ACH') {
                        $('.ACHOptOut_Checkbox').removeClass('d-none');
                    }
                    if (err && err.responseJSON && err.responseJSON.modalContent) {
                        $(document).trigger('qfmodal:show', { html: err.responseJSON.modalContent });
                        return;
                    }
                    cache.$qfModalError.modal('show');
                }
            });
        } else {
            $.spinner().stop();
        }
        return false;
    });

    $('.ACHOptOut_Checkbox').on('click', function () {
        if ($('#ACHOptOutCheckbox').is(':checked') && $('.ACHOptOut_Checkbox').length) {
            $('.ACHOptOut_Checkbox').addClass('d-none');
            $('.payment__type-item.selected').click();
        } else {
            $('#ACHOptOutCheckbox').prop('checked', false);
        }
    });

    /**
     * Send info to backend for logging user behavior
     * @param {Object} payLoad required params
     * @returns {void} data
     */
    function checkIfUserLeftPage(payLoad) {
        const url = cache.$userDetect.attr('href');
        $.ajax({
            url: url,
            method: 'POST',
            data: payLoad
        });
    }

    /**
     * Return service call for Zuora
     * @param {Object} response required params
     * @returns {void} redirect
     */
    function callback(response) {
        cache.$pleaseWaitForOrderModal.modal('show');
        $(window).on('beforeunload', () => {
            let payLoad = {
                userAgent: window.navigator.userAgent,
                processSuccess: cache.processSuccess
            };

            checkIfUserLeftPage(payLoad);
        });

        $.spinner().start();
        $(document).trigger('payment:submitpay', { company: cache.$businessName.val() ? 'yes' : 'no' });  // tracking

        $.ajax({
            type: 'POST',
            url: response.redirectUrl,
            data: {
                signature: response.signature,
                refId: response.refId,
                token: response.token
            },
            success: function (data) {
                if (data.redirectUrl) {
                    $.ajax({
                        type: 'POST',
                        url: data.redirectUrl + '?cc=true',
                        success: function (data1) {
                            cache.processSuccess = true;
                            $.spinner().stop();
                            if (data1.leadError) {
                                window.location = data1.redirectUrl;
                            }
                            $('.bf__main')
                              .append('<div>')
                              .hide()
                              .append(data1);
                        },
                        error: function (err) { // eslint-disable-line
                            $.spinner().stop();
                            $('#ACHOptOutCheckbox').prop('checked', false);
                            cache.processSuccess = false;
                            cache.$qfModalError.modal('show');
                        }
                    });
                }
            },
            error: function (err) { // eslint-disable-line
                $.spinner().stop();
                $('#ACHOptOutCheckbox').prop('checked', false);
                cache.processSuccess = false;
                cache.$qfModalError.modal('show');
            }
        });
    }
})(jQuery);
