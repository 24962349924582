'use strict';

/** Footer collapes function works on resize
 * */
function footerMenuCollapes() {
    if ($('html.manage-portal').length) {
        $('.js-feedback-link').on('click', function (event) {
            event.preventDefault();
            const $ooTab = $('#oo_tab');

            if ('ontouchend' in window && $ooTab.length > 0) {
                const tabElement = $ooTab[0];
                let customEvent = new Event('touchstart');
                tabElement.dispatchEvent(customEvent);
                customEvent = new Event('touchend');
                tabElement.dispatchEvent(customEvent);
            } else {
                $ooTab.click();
            }
        });
    }

    if ($(window).width() <= 992) {
        var allPanels = $('.sub-menu-footer').hide();
        $('.footer--col-header').on('click', function () {
            allPanels.slideUp();
            if ($(this).hasClass('footer-header-open')) {
                $('.footer--col-header').removeClass('footer-header-open');
            } else {
                $('.footer--col-header').removeClass('footer-header-open');
                $(this).next().slideDown();
                $(this).addClass('footer-header-open');
            }
            return false;
        });
    } else {
        $('.sub-menu-footer').show();
    }
}
module.exports = {
    footerMenuCollapes: footerMenuCollapes
};
