/* eslint-disable valid-jsdoc*/
// TODO QFCC: fix jsdoc and add multiple props

/**
 * Replaces {{xyz}} keys with dynamic values
 * @param {string} replaceProp used for dynamic replacement.
 * @param {string} str a string containing {{PLACEHOLDER}} keys to be replaced
 * @param {string} v is the value we are replacing
 * @returns the str with replaced {{xyz}} values
 */
function replaceContentDynamicValues({ replaceProp, str, v }) {
    let rtnStr = str;
    const replacement = '{{' + v + '}}';

    rtnStr = rtnStr.replace(replacement, replaceProp);

    return rtnStr;
}

/**
 * @param {number} ms - time to logout walled garden user
 * @param {string} url - logout url
 * @returns
 */
function redirectOnTimer({ ms, url }) {
    return setTimeout(() => {
        window.location.href = url;
    }, ms);
}

/**
 * Open chat window
 */
function triggerChat() {
    $('#qf_floating_chat').trigger('click');
}

/**
 * Swipe value on click
 */
function swipeValues(v1, v2, el) {
    el.on('click', () => {
        v1.toggle();
        v2.toggle();
    });
}

/**
 * formatting js dates
 */
let formatJsDate = (inputDate, outputDateFormat) => {
    let d = new Date(inputDate);
    let getMonth = d.getMonth() + 1;
    let formattedMonth = getMonth.toString().length === 1 ? '0' + getMonth : getMonth;
    let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let fullMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    let fullYear = d.getFullYear();
    let outputDate = '';

    if (outputDateFormat === 'd/m/y') {
        outputDate = d.getDate() + '/' + formattedMonth + '/' + fullYear.toString().slice(-2);
    } else if (outputDateFormat === 'd/m/Y') {
        outputDate = d.getDate() + '/' + formattedMonth + '/' + fullYear;
    } else if (outputDateFormat === 'm/d/y') {
        outputDate = formattedMonth + '/' + d.getDate() + '/' + fullYear.toString().slice(-2);
    } else if (outputDateFormat === 'm/d/Y') {
        outputDate = formattedMonth + '/' + d.getDate() + '/' + fullYear;
    } else if (outputDateFormat === 'F j, Y') {
        outputDate = fullMonths[d.getMonth()] + ' ' + d.getDate() + ', ' + fullYear;
    } else if (outputDateFormat === 'M j, y') {
        outputDate = months[d.getMonth()] + ' ' + d.getDate() + ', ' + fullYear.toString().slice(-2);
    } else if (outputDateFormat === 'j F Y') {
        outputDate = d.getDate() + ' ' + fullMonths[d.getMonth()] + ' ' + fullYear;
    } else if (outputDateFormat === 'j M Y') {
        outputDate = d.getDate() + ' ' + months[d.getMonth()] + ' ' + fullYear;
    }
    return outputDate;
};

module.exports = {
    replaceContentDynamicValues,
    redirectOnTimer,
    triggerChat,
    swipeValues,
    formatJsDate
};
