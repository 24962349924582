'use strict';
const month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

/**
 * configure date to reflect in prepurchase modal popup
 * @param {string} polygonEcd date string
 * @returns {string} formatted date
 */
function createPolygonDate(polygonEcd) {
    const mydate = new Date(polygonEcd);

    return month[mydate.getMonth()] + ' ' + mydate.getDate() + ', ' + mydate.getFullYear();
}


module.exports = {
    createPolygonDate: createPolygonDate
};
