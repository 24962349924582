'use strict';

const $voiceResumeBtn = $('#voice-resume-modal-btn');
const $voiceResumeModal = $('#voice-resume-modal');
const $voiceResumeDetails = $('#voice-resume-details');
const $voiceResumeConfirmation = $('#voice-resume-confirmation');
const $voiceResumeContinue = $('#voice-resume-continue');
const $voiceResumeSubmit = $('#voice-resume-submit');
const $voiceResumeCancel = $('#voice-resume-cancel');
const $voiceResumeCheck = $('#voice-resume-check');

module.exports = {
    initEvents: function () {
        // Show modal
        $voiceResumeBtn.on('click', function (e) {
            e.preventDefault();
            $voiceResumeModal.modal('show');
        });
        // Enable/Disable Continue button
        $voiceResumeCheck.on('click', function () {
            $voiceResumeContinue.attr('disabled', !this.checked);
        });
        // Show Submit Hide Continue
        $voiceResumeContinue.on('click', function (e) {
            e.preventDefault();
            $voiceResumeDetails.hide();
            $voiceResumeContinue.hide();
            $voiceResumeConfirmation.removeClass('d-none');
            $voiceResumeSubmit.removeClass('d-none');
        });
        // Reset if Canceled
        $voiceResumeCancel.on('click', function (e) {
            e.preventDefault();
            $voiceResumeContinue.prop('disabled', true);
            $voiceResumeCheck.prop('checked', false);
            $voiceResumeConfirmation.addClass('d-none');
            $voiceResumeSubmit.addClass('d-none');
            $voiceResumeDetails.show();
            $voiceResumeContinue.show();
        });
    }
};
