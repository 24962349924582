const base = require('../product/base');

const cache = {
    $qfModalError: $('#qf-modal-error'),
    $refreshBtn: $('#qf-modal-error .qf-btn-primary-action')
};

/**
 * @param {HTMLElement} $el - add to card button element
 * @returns {string} product ID
 */
function getRecProductPid($el) {
    let pid;
    if ($el.closest('.rec-product').length) {
        pid = $el.data('pid');
    } else {
        pid = $el.closest('.js-rec-product').find('.option.selected').data('pid');
    }

    return pid;
}

module.exports = {
    addToCartRecProduct: function () {
        $(document).on('click', 'button.js-add-to-cart-rec-product', function () {
            var $this = $(this);
            var $productContainer = $this.closest('.js-rec-product');
            var addToCartUrl;
            var pid;

            $('body').trigger('product:beforeAddToCart', this);
            pid = getRecProductPid($this);
            addToCartUrl = $this.closest('.recommendations').find('.add-to-cart-recprod-url').val();

            var form = {
                pid: pid,
                quantity: 1
            };

            if ($this.closest('.js-rec-product').length) {
                form.options = base.getRecOptions($productContainer);
            }

            $(this).trigger('updateAddToCartFormData', form);
            if (addToCartUrl) {
                $.ajax({
                    url: addToCartUrl,
                    method: 'POST',
                    data: form,
                    success: function (data) {
                        $('body').trigger('product:afterAddToCart', data);
                        $.spinner().stop();
                        base.miniCartReportingUrl(data.reportingURL);
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            }
        });
    },

    addProdToCart: function () {
        $(document).on('click', 'a.js-add-prod-to-cart', function (e, dataObj) {
            e.preventDefault();
            let $selectedProduct = $('#qf-tiles .product.selected');
            let redirectUrl = $(this).attr('href');

            $(this).trigger('product:beforeAddToCart');

            var forcedRedirect = false;
            let addToCartUrl = $selectedProduct.hasClass('js-no-thanks') ? $('.no-thanks-url').val() : $('.add-to-cart-url').val();
            let $selectedOption = $('#qf-tiles .option.selected');

            let form = {
                pid: '',
                quantity: 1,
                cgid: $("[name='cgid']").val(),
                vgid: '',
                CVSubscriberAgreement: '',
                CVPortAgreement: '',
                portingRequested: ''
            };
            if ($('#911TermsConditionCheckbox').is(':checked')) {
                form.CVSubscriberAgreement = true;
            }

            if ($('#transferTermsConditionCheckbox').is(':checked')) {
                form.CVPortAgreement = true;
                form.portingRequested = true;
            } else {
                form.CVPortAgreement = false;
                form.portingRequested = false;
            }
            if ($(this).attr('data-enableC2FMigrationButtons') !== undefined && $(this).attr('data-enableC2FMigrationButtons').length > 0) {
                form.isContinueWithoutPhone = dataObj && (dataObj.isContinueWithoutPhone === true) ? true : false; //eslint-disable-line
            }
            if ($selectedProduct.length) {
                form.pid = $selectedProduct.data('pid');
                form.vgid = $selectedProduct.data('vgid');
            }
            if ($('.product.selected').data('equipment') === '360 WiFi Enabled' && $('#showCoveragePage').val() === 'true') {
                forcedRedirect = true;
                redirectUrl = $('.wifi-pods-url').val();
            }

            if (!$selectedOption.hasClass('category-wrapper__byo') && addToCartUrl && form.pid !== '') {
                $.spinner().start();
                $.ajax({
                    url: addToCartUrl,
                    method: 'POST',
                    data: form,
                    success: function (data) {
                        $.spinner().stop();
                        if (data.success === false) {
                            if (data.modal === 'add-to-cart-failed') {
                                $('#qf-modal-error-dummy').html(data.modalContent);
                                cache.$qfModalError.html($('#qf-modal-error-dummy #qf-modal-error').html()).modal('show');
                                $('#qf-modal-error-dummy').empty();
                            }
                        } else if (data.ableToAddProduct && data.ableToAddProduct.flag === false) {
                            location.href = data.ableToAddProduct.redirectUrl;
                        } else if (data.nextUrl && forcedRedirect !== true) {
                            if (!form.isContinueWithoutPhone) {
                                let name = $selectedProduct.data('pname');
                                let price = $selectedProduct.data('price');
                                let dimension95 = form.portingRequested ? 'yes' : 'no';
                                $('body').trigger('gtm:cv:continueWithphoneSelection', { name: name, price: price, dimension95: dimension95 });
                            } else if (form.isContinueWithoutPhone === true) {
                                $('body').trigger('gtm:cv:continueWithoutphone');
                            }
                            location.href = data.nextUrl;
                        } else {
                            location.href = redirectUrl;
                        }
                    },
                    error: function (err) {
                        $.spinner().stop();
                        cache.$qfModalError.modal('show');
                        if (err.responseJSON.redirectUrl) {
                            location.href = err.responseJSON.redirectUrl;
                        }
                    }
                });
            }
        });
    }
};
