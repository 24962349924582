'use strict';
const { redirectOnTimer } = require('telco/helpers/utils.js');
const $modal = $('#global-error-handling-modal');
const $modalData = $('#handle-error-modal');
const $walledGardenData = $('#walled-garden-data');
const $mpSuspendModal = $('#mp-modal-suspend');

/**
 * errorModalHandle - check if middleware error occured and display the error modal
 */
function errorModalHandle() {
    if (!$modal.length) return;

    $modal.modal('show');

    if ($modalData.data('errorCid')) return;
    const title = $modalData.data('errorTitle');
    const message = $modalData.data('errorBody');
    $modal.find('.modal-title').text(title);
    $modal.find('.modal-body').text(message);
}

/**
 * suspendModal - redirect user with account status - suspend to payment page
 */
function suspendModalRedirect() {
    const $suspendSubmit = $('#mp-suspend-btn');
    if (!$suspendSubmit.length) return;

    $suspendSubmit.on('click', function (e) {
        e.preventDefault();
        $.spinner().start();

        $.ajax({
            url: this.href,
            method: 'POST',
            success: function ({ success, redirectUrl }) {
                $.spinner().stop();
                if (success) {
                    window.location.href = redirectUrl;
                }
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
}

module.exports = {
    init: function () {
        if ($walledGardenData.length) {
            const { timeRemaining, logoutURL, isSuspendModalShow } = JSON.parse($walledGardenData.val());

            if (isSuspendModalShow) {
                $mpSuspendModal.modal('show');
            }

            redirectOnTimer({
                ms: timeRemaining,
                url: logoutURL
            });
        }
    },
    errorModalHandle,
    suspendModalRedirect
};
