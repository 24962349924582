var base = require('base/product/base');


// Using $.extend we can pass in the original product/base file and then anything provided will either be overwritten or added.
module.exports = $.extend({}, base, {
    getRecOptions: function ($productContainer) {
        var options = $productContainer
            .find('.option-group')
            .map(function () {
                var $elOption = $(this).find('.option.selected');
                var selectedValueId = $elOption.data('option');
                return {
                    optionId: $(this).find('.option.selected').data('id'),
                    selectedValueId: selectedValueId
                };
            }).toArray();
        return JSON.stringify(options);
    }
});
