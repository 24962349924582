'use strict';

/**
 * Footer Floating Cart
 */
function initFloatingCart() {
    // Show/hide the cart pull-up panel when the arrow icon is clicked
    $('.viewFloatingCart:not(".notclickable")').on('click', function () {
        $('.floatingCartItemsInfo').slideToggle();
        $(this).toggleClass('expand');
    });
    $(document).click(function (event) {
        var target = $(event.target);
        if (!target.is('.floatingCartItemsInfo') && !target.is('.viewFloatingCart')) {
            $('.floatingCartItemsInfo').hide();
            $('.viewFloatingCart').removeClass('expand');
        }
    });
}

/**
 * Enable tooltip for 360 wifi and 360 wifi pods
 */
function enableTooltips() {
    $('[data-toggle="tooltip"]').tooltip();
}

module.exports = {
    initFloatingCart: initFloatingCart,
    initEvents: function () {
        enableTooltips();
    }
};
