'use strict';

const ADDRESS_FIELDS_TYPES = ['street_number', 'route', 'locality', 'administrative_area_level_1', 'postal_code', 'country'];

/**
 * @param {Array.<string>} gaTypeArr - array of strings with GA types
 * @returns {null|string} - null|string GA type value
 */
function parseAddressResult(gaTypeArr) {
    if (gaTypeArr.length === 0) return null;

    for (const field of ADDRESS_FIELDS_TYPES) {
        if (gaTypeArr[0] === field) {
            return field;
        }
    }
    return null;
}

/**
 * Returns data about an address from google geocoding api
 * @param {string} address user's address
 * @param {string} postalCode user's postal code to restrict the results
 * @returns {Object} geocoded data from google
 */
function getGeocodeData(address, postalCode = null) { // eslint-disable-line
    const geocoder = new google.maps.Geocoder(); // eslint-disable-line
    let geoResult = geocoder.geocode({
        address: address,
        componentRestrictions: {
            country: 'US'
            // zip code field removed from AddressChecker-Show
            // postalCode: postalCode
        }
    }, (results, status) => {
        // eslint-disable-next-line
        if (status !== 'OK') console.log('Geocoder failed due to: ' + status);
        // eslint-disable-next-line
        if (!results) console.log('No results found');
        return results;
    });
    return geoResult;
}

/**
 * Parses geocode result to create an object with address components
 * @param {Object} geocodeResponse result from google geocode api
 * @returns {Object} object with adress components
 */
function getAddressObj(geocodeResponse) {
    if (!geocodeResponse) {
        return {};
    }
    const addressFields = {};
    const geocodeResults = geocodeResponse.results[0];

    for (const addressComponent of geocodeResults.address_components) {
        let addressType = parseAddressResult(addressComponent.types);

        if (addressType) {
            addressFields[addressType] = addressComponent.short_name;

            if (addressType === 'administrative_area_level_1') {
                addressFields.state_name_long = addressComponent.long_name;
            }
        }
    }
    addressFields.lat = geocodeResults.geometry.location.lat();
    addressFields.long = geocodeResults.geometry.location.lng();
    addressFields.addressGoogleFormatted = geocodeResults.formatted_address;
    addressFields.isRooftop = geocodeResults.geometry.location_type === 'ROOFTOP';

    return addressFields;
}

module.exports = {
    getGeocodeData,
    getAddressObj
};
