'use strict';

// eslint-disable-next-line
function createPopovers() {
    $('[data-popover-target]').popover({
        html: true,
        trigger: 'hover focus',
        placement: 'top',
        delay: {
            show: 500,
            hide: 1000
        },
        content: function () {
            return $($(this).data('popover-target')).html();
        }
    });
}

module.exports = {
    createPopovers: createPopovers
};
