/**
 * Appends modal HTML to DOM, and automatically calls modal();
 * @param {Object} params - params object
 * @paream {string} params.html - the modal html
 * @paream {boolean} params.show - defaults to true, automatically shows the modal after it's appended to DOM
 * @returns {Object} jQuery object containing the modal element.
 */
const createModal = ({ html, show = true }) => {
    const $modalEl = $(html);
    $('body').append($modalEl);
    if (show) {
        $modalEl.modal('show');
    }
    return $modalEl;
};

/**
 * Async request for loading a modal
 * @param {Object} params - params object
 * @paream {string} params.html - the modal html
 * @paream {boolean} params.show - defaults to true, automatically shows the modal after it's appended to DOM
 * @returns {Object} jQuery object containing the modal element.
 */
const loadModal = async ({ url, show = true }) => {
    return new Promise((resolve, reject) => {
        $.get(url)
            .done((html) => {
                resolve(createModal({ html, show }));
            }).fail((err) => {
                reject(err);
            });
    });
};

module.exports.createModal = createModal;
module.exports.loadModal = loadModal;
