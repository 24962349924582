'use strict';
class NotificationMessage {
    constructor(message = '', {
        duration = 2000,
        type = '',
        title = '',
        position = 'fixed'
    } = {}) {
        this.duration = duration;
        this.title = title;
        this.type = type;
        this.message = message;
        this.position = position;
        this.render();
    }

    get template() {
        // supported notification type - success, error
        return `
        <div class="notification ${this.type} ${this.position}" style="--value:${this.duration / 1000}s">
            <div class="timer"></div>
            <div class="inner-wrapper">
              ${this.renderTitle(this.title)}
                <div class="notification-body" data-elem="body">
                    ${this.message}
                </div>
            </div>
            <div class="notification-close" data-elem="close"></div>
        </div>
        `;
    }

    render() {
        const element = document.createElement('div');
        element.innerHTML = this.template;
        this.element = element.firstElementChild;
        this.subElements = this.getSubElements(this.element);
    }

    renderTitle(title) {
        return title ? `<div class="notification-title" data-elem="title">
                    ${this.title}
              </div>
          ` : '';
    }

    show(el = document.body) {
        const $notification = $('.notification');
        if ($notification.length) {
            $notification.remove();
        }

        el.append(this.element);
        this.initEventListeners();
        setTimeout(() => this.destroy(), this.duration);
        return this;
    }


    getSubElements(element) {
        const result = {};
        const elements = element.querySelectorAll('[data-elem]');
        for (const subElement of elements) {
            const name = subElement.dataset.elem;
            result[name] = subElement;
        }
        return result;
    }

    initEventListeners() {
        this.subElements.close.addEventListener('click', () => {
            this.destroy();
        });
    }

    destroy() {
        this.remove();
        this.element = null;
        this.subElements = {};
    }

    // eslint-disable-next-line
    remove() {
        if (this.element) {
            return this.element.remove();
        }
    }

    getDuration() {
        return this.duration;
    }
}

module.exports = NotificationMessage;

