'use strict';

const cache = {
    $searchCloseIcon: $('.js-search-close'),
    $searchExecuteIcon: $('.js-search-execute'),
    $searchIcon: $('.js-search-icon'),
    $searchInput: $('.js-search-bar'),
    $searchLabel: $('.js-search-label'),
    $searchLinks: $('.js-search-links'),
    $jsGtmSearchLinksClick: $('.js-search-links ul li')
};

/**
 * execute search with text in search input
 */
function executeSearch() {
    var q = cache.$searchInput.val();
    if (q.trim().length === 0) {
        return;
    }
    q = q.replace('/', '');
    var encodedSearchTerms = encodeURIComponent(q);
    var searchURL = cache.$searchExecuteIcon.data('url');
    window.open(searchURL.replace('{searchterms}', encodedSearchTerms), '_blank');
}

/**
 * show search links
 */
function showSearchLinks() {
    cache.$searchLinks.removeClass('d-none');
}

/**
 * hide search links
*/
function hideSearchLinks() {
    cache.$searchInput.val('');
    cache.$searchLinks.addClass('d-none');
}

/**
 * show search bar
 */
function showSearchBar() {
    $('body').addClass('search-bar-open');
}

/**
 * hide search bar
 */
function hideSearchBar() {
    $('body').removeClass('search-bar-open');
    hideSearchLinks();
}

module.exports = {
    initEvents: function () {
        cache.$searchIcon.on('click', function () {
            showSearchBar();
            $('body').trigger('search:searchBarClick');
        });

        cache.$searchLabel.on('click', function () {
            showSearchBar();
        });

        cache.$searchCloseIcon.on('click', function () {
            hideSearchBar();
        });

        cache.$searchInput.on('keypress', function () {
            showSearchLinks();
        });

        cache.$searchExecuteIcon.on('click', function () {
            executeSearch();
        });

        cache.$searchInput.on('keypress', function (e) {
            var key = e.which;
            // check for the enter key code
            if (key === 13) {
                executeSearch();
                return false;
            }
            return true;
        });

        cache.$jsGtmSearchLinksClick.on('click', function () {
            let linkClicked = $(this).text();
            $('body').trigger('search:suggestionLinkClick', { linkClicked: linkClicked });
        });
    }
};
