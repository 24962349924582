'use strict';

const cache = {
    $qfModalPromoDetails: $('#qfModalPromoDetails'),
    $promoDetailsIcon: $('.js-review__section-promoDetails')
};

/**
 * Triggers modal layer on click of the promo details icon.
 */
function showPromoDetailsModal() {
    cache.$promoDetailsIcon.on('click', function () {
        cache.$qfModalPromoDetails.modal('show');
    });
}

module.exports = {
    initEvents: function () {
        showPromoDetailsModal();
    }
};
