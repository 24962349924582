'use strict';

const cache = {
    $currentTime: $('#current-time'),
    $qfModal: $('#qfModalTimeExpired'),
    $qfSessionModal: $('#qfModalSessionExpired')
};

let entryTime = parseFloat(cache.$currentTime.val());
let appointmentExpiredTime = cache.$qfModal.data('appointment-expired-time');
let redirectUrl = cache.$qfModal.data('redirect-url') || '/';
let validateSession = cache.$qfModal.data('validatesession-url');
let expiredTime = entryTime + parseFloat(appointmentExpiredTime);
let timeNow = new Date().getTime();
let timeElapsed = timeNow - entryTime;
let timeOut = appointmentExpiredTime - timeElapsed;

if (cache.$currentTime.length > 0) {
    $(function () {
        setTimeout(function () {
            if (entryTime < expiredTime) {
                $.ajax({
                    url: validateSession,
                    type: 'get',
                    dataType: 'json',
                    success: function (data) {
                        if (data.isValidSession) {
                            cache.$qfModal.modal('show');
                            cache.$qfModal.on('hidden.bs.modal', function () {
                                window.location.href = redirectUrl;
                            });
                        } else {
                            cache.$qfSessionModal.modal('show');
                            cache.$qfSessionModal.on('hidden.bs.modal', function () {
                                window.location.href = cache.$qfSessionModal.data('redirect-url');
                            });
                        }
                    },
                    error: function () {
                        cache.$qfModal.modal('show');
                        cache.$qfModal.on('hidden.bs.modal', function () {
                            window.location.href = redirectUrl;
                        });
                    }
                });
            }
        }, timeOut);
    });
}
